@import "./_variables.scss";

.professional-dialog{
  max-width: 732px;
  height: auto;
  width: 100%;
  flex-direction: row;
  align-items: stretch;

  .professional-dialog-back-icon{
    cursor: pointer;
    position: absolute;
    top:9px;
    left: 9px;
    path{
      fill:$primary-color;
    }
  }

  .professional-dialog-imatge{
    flex: 0.32 1;
    background-color: #f65d68;
    background-position: center center;
    background-size: cover;
    min-height: 206px;
    &.image{
      background-image: url("../img/professional-default.png");
    }
  }
  .professional-dialog-content{
    flex: 0.68;
    padding: 40px 32px 24px 24px;
    align-items: end;
    position: relative;
    &.professional-dialog-loading{
      justify-content: center;
      align-items: center;
      min-height: 300px;
    }
    .professional-dialog-close-icon{
      cursor: pointer;
      position: absolute;
      top:9px;
      right: 9px;
      path{
        fill:$primary-color;
      }
    }
    .split-content{
      display: flex;
      margin-top: 16px;
      margin-bottom: 8px;
    }
    .professional-dialog-content-buttons{
      display: flex;
      gap:8px;
      height: 24px;
    }
    h4 {
      margin:0;
    }
    svg{
      height: 24px;
      width: 24px;
    }
    .divider{
      width: 100%;
      height: 0.5px;
      background-color:$light-grey;
    }
    .professional-dialog-content-info{
      width: 100%;
      margin-bottom: 8px;
        p{
          margin:0
        }
    }
    .professional-social-links {
      display: flex;
      gap:8px;
      align-items: center;
      a{
        width: 24px;
        height: 24px;
      }
      path{
        &:first-child{
          fill: $dark-grey;
        }
      }
    }
  }

  @media screen and (max-width: $mobile-screen){
    flex-direction: column;
    .professional-dialog-imatge{
      background-image: url("../img/professional-default-mobile.png");
    }
    .professional-dialog-content{
      padding: 15px 16px 31px 16px;
      .professional-dialog-close-icon{
        top:11px;
        svg{
          height: 38px;
          width: 38px;
        }
      }
      .split-content{
        flex-wrap: wrap;
      }
      h4{
        margin-top: 24px;
      }
      .professional-dialog-content-buttons{
        height: 32px;
        gap:24px;
        svg{
          height: 32px;
          width: 32px;
        }
      }
      .professional-social-links {
        gap:29px;
        a{
          width: 40px;
          height: 40px;
          svg{
            width: 40px;
            height: 40px;
          }
        }
      }
    }    
  }
}