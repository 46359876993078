@import "../_variables.scss";

.text-input {
    min-height: 32px;
    &.text-area{
        height: 100%;
    }

    .MuiInput-underline::after {
        border-color: $accent-color !important;;
    }

    .MuiFormLabel-root.Mui-focused {
        color: $accent-color !important;
    }
    
    .MuiFormControl-root {
        font-family: 'GeneralSans-Light';
    }

    .MuiFormLabel-root {
        display: flex;
        font-family: 'GeneralSans-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 21.6px;
        text-align: left;

        .description {
            margin-left: 8px;
            order: 999;
            font-size: 14px;
        }
    }
    
    .MuiInput-input {
        font-size: 16px;
        font-weight: 400;
        line-height: 21.6px;
        text-align: left;
        font-family: 'GeneralSans-Regular';
    }
}

.Mui-error {
    color: $error-color !important;
}


.textarea {
    display: flex;
    flex-direction: column;

    .error-textarea {
        color: $error-color;
    }
}