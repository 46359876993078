@import "../_variables.scss";

.basic-chips{
    min-width: 85px;
    height: 24px;
    border-radius: 4px;
    border: solid 1px #A6A6A6;
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    text-transform: capitalize;
    font-family: "GeneralSans-Regular";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.015em;
    text-align: left;
    color: $primary-color;

    cursor: pointer;
    
    svg {
        height: 16px;
        width: 16px;
        path{
        fill: $accent-color;
        }
    }

    &:hover{
        background-color: $accent-color;
        box-shadow: 0px 2px 1px 0px rgba(#A6A6A6, 0.5);
        color: white;
        svg path{
            fill: white;
        }
    }
}

.MuiTooltip-popper[data-popper-placement*="bottom"]{
    .MuiTooltip-tooltip {
        background-color: #F0EBE8;
        color: black;
        font-family: "GeneralSans-Regular";
        box-shadow: 0px 3px 4px 0px #00000040;
        padding: 0px 8px;
        font-size: 0.875rem;
        margin: 0;
        margin-top: 8px;
    }
}


.withoutBorder{
    border:none;
}
    