@import "../_variables.scss";

.filter-button{
    cursor: pointer;
    height: 56px;

    padding: 0px 16px;
    border-radius: 4px;
    border: solid 1px #5C5958;
    background-color: transparent;
    transition: all .3s ease-in-out;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    

    /* text-transform: capitalize; */
    font-family: "GeneralSans-Regular";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.015em;

    color: #49454F;
    
    svg path{
        transform: scale(.8);
        fill: #49454F;
    }

    &:hover{
        background-color: $light-grey-2;
        box-shadow: 0px 1px 3px 1px #00000026;
        box-shadow: 0px 1px 2px 0px #0000004D;
        border-color: transparent;
    }

    &.selected{
      cursor: auto;
      background-color: #E90C1B;
      color: white;
      border-color: transparent;
      svg path{
          fill: white;
      }
      &:hover{
          box-shadow: none;
      }
    }


    @media screen and (min-width: $mobile-screen){
      height: 32px;
    }
}
    