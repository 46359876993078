///STYLES ACCOUNT

.account-container-elements{
    display: flex;
    flex-wrap: wrap;
    .account-element{
      border: 1px solid black;
      padding: 10px;
      width: 30%;
      height: 45px;
      margin-bottom: 20px;
      border: 1px solid;
      margin-right: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      .account-info{
        font-weight: 600;
      }
    }
  }

.selector-entity-account{
  background-color: black;
  box-shadow: 10vw 0px 0px 0px black, -10vw 0px 0px 0px black;
  color: white;
}

.go-back-container {
  display: flex;
  cursor: pointer;
  padding: 10px 0px;
  background-color: #F5F4F0;
  box-shadow: 10vw 0px 0px 0px #F5F4F0, -10vw 0px 0px 0px #F5F4F0;
  &.black{
    background-color: #3D3338;
    box-shadow: 10vw 0px 0px 0px #3D3338, -10vw 0px 0px 0px #3D3338;
    color: white  ;
  }
  &.no-bg{
    background-color: unset;
    box-shadow: unset;
  }
}  

.acreditats-container{
  display: flex;
  flex-wrap: wrap;

  .secondary-contact{
    background-color: #f5f4f0;
    padding: 20px;
  }
  
  .show-item-container-grid{
    width: 30vw;
    margin-right: 1vw;
    min-height: 72px;
    margin-bottom: 20px;
    background-color: #f5f4f0;
    cursor: pointer;
    display: flex;
    border-radius: 10px;
    @media screen and (max-width: 768px) {
      width: 100%;
      background-color:unset;
      border-top: 1px solid #d0cdc7;
      border-radius: 0px;
      margin-bottom: 0px;
    }
  }
  .show-item-container-grid:hover{
    background-color: #635c5f;
    color: white;
  }
  
  .name-info{
    align-items: center;
    /* display: flex; */
    /* flex-direction: column; */
    width: 100%;
    padding: 10px;
    text-align: left
  }
  .actions-info{
    margin-left: auto;
    padding: 20px 0;
    align-items: center;
    display: flex;
    * {
      margin: 0px 10px;
    }
  }
}

.user-info-container{
    display: flex;
    padding: 20px 0px;
    align-items: center;
    border-top: 1px solid #B2AEB0;
    background-color: #F5F4F0;
    box-shadow: 10vw 0px 0px 0px #F5F4F0, -10vw 0px 0px 0px #F5F4F0;
    @media screen and (max-width: 768px) {
      display: block;
    }
    .user-image{
        img{
            width: 160px;
            height: 160px;
        }
    }
    .user-info-1{
      
      margin-left: 6vw;
      text-align: justify;
      width: 25vw;
      @media screen and (max-width: 768px) {
        width: unset;
        margin-left: unset;
      } 
    }
    .user-info-2{
        margin-left: auto;
        text-align: justify;
        width: 25vw;
        @media screen and (max-width: 768px) {
          width: unset;
          margin-left: unset;
        } 

    }
}

.personal-data-container{
  display: flex;
  .qr-container{
    canvas{
      width: 210px !important; 
      height: 210px !important;
    }
  }
  .data-container{
    font-size: 14px;
    padding-left: 20px;
    display: block;
    text-align: left;
  }
}