@import "./_variables.scss";


$leftSpace : 25px;

// PROPOSALS PAGE : Proposals

.proposals {
  width: 100%;
  box-sizing: border-box;

  .proposalsTop-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: $leftSpace;

    .addProposals-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      gap: 20px;

      p {
        max-width: 632px;
      }
    }

    .regulations-container {
      width: 376px;
      height: 224px;
      padding: 0px 20px;
      border-radius: 0px 0px 25px 0px;
      border: solid 1px $accent-color;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        width: 328px;
        height: 72px;
      }
    }
  }

  .proposalItem-content {
    padding: 15px 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .proposalItem-content_element {
      width: 33%;
      min-width: 150px;

      p {
        margin: 0;
      }
    }

    .proposalItem-content_element p:nth-child(1) {
      font-family: "GeneralSans-Regular";
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      text-align: left;
    }
  }

  .artisticProposals-container,
  .manager-container,
  .artist-container {
    margin: 25px 0px 40px $leftSpace;
  }

  .artist-container {
    width: 95%;
  }

  .notInYear-container {
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      color: $info-color;
    }
  }


  // PROPOSALS COMPONENT : ProposalEntities

  .entity-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }


  // PROPOSALS COMPONENT : ProposalsEntitiesListItem

  .proposalItem-container,
  .proposalEntities-managerContainer,
  .proposalEntities-artistContainer {
    margin-bottom: 15px;
    border-radius: 0px 0px 25px 0px;
    border: solid 1px $light-grey;
    /* min-width: 500px; */
  }

  .proposalEntities-managerContainer {
    width: 48%;

    .proposalItem-header {
      justify-content: space-between;
    }
  }

  .proposalEntities-artistContainer {
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    height: 80px;
  }

  .proposalItem-header {
    gap: 10px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0px 15px;
    background-color: #F0EBE899;
    /* margin-bottom: 15px; */

    p {
      margin: 0px;
      font-family: 'Aventa Variable';
      font-size: 16px;
      font-weight: 800;
      line-height: 24px;
      text-align: left;
    }
  }


  .proposalEntities-content {
    display: flex;
    align-items: center;

    .userImg {
      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 8px;
      background: $accent-color;
      margin: 10px 20px;

      font-family: "GeneralSans-Regular";
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 600;
      color: white;
    }

    .proposalEntities-text {
      p {
        font-family: "GeneralSans-Regular";
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        margin: 0;
      }

      p:nth-child(1) {
        font-weight: 600;
      }

      p:nth-child(2) {
        font-weight: 500;
        color: $accent-color;
      }
    }
  }

  // DETAILS ( TYPOGRAPHY AND SEPARATOR )

  .separator {
    border-bottom: 1px solid;
    opacity: 0.2;
    margin: 15px 0px;
  }

  h1 {
    margin: 25px 0px 5px $leftSpace;
  }

  h4 {
    margin-bottom: 35px;
    width: 80%;
  }

  h5 {
    margin-bottom: 25px;
  }

  p {
    margin: 14px 0px;
  }

  @media screen and (max-width: $mobile-screen) {

    .artisticProposals-container,
    .manager-container,
    .artist-container {
      margin: 25px 0px 40px 0px;
    }

    .proposalsTop-container {
      padding-left: 0px;
      .regulations-container {
        width: 100%;
        height: auto;
        padding: 16px 24px;
        p {
          width: auto;
          height: auto;
        }
      }
    }

    h1 {
      margin: 0px;
    }

    .proposalItem-header {
      justify-content: space-between;
      height: auto;
      padding: 8px;
      gap: 8px;
    }

    .separator {
      margin: 0px 15px;
      margin-top: 16px;
    }

    .proposalItem-content {
      gap: 10px;
    }

    .proposalItem-content_element,
    .proposalEntities-managerContainer,
    .proposalEntities-artistContainer {
      width: 100% !important;
    }

    .basic-chips{
      height: 40px;
    }

  }

}

.proposalRegulationDialog {

  .MuiPaper-root {
    padding: 20px 0px;
    height: 90%;
  }

  .MuiDialogContent-root div {
    padding: 30px;
  }
}

// DETAILS ( TYPOGRAPHY AND SEPARATOR )
.separator {
  border-bottom: 1px solid;
  opacity: 0.2;
  margin: 15px 0px;
}