@import "./_variables.scss";

.pagament-detail-page_content{
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.pagament-pendent {
    font-family: 'GeneralSans-Semibold';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-align: left;
}

.pagament-info {
    max-width: 528px;
    border-radius: 0px 0px 26px 0px;
    border: 1px solid $light-grey;

    .separator{
        margin: 0px 10px;
        opacity: 1;
        border-color: #5C5958;
    }
}

.pagament-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 32px;
    /* border-bottom: 1px solid $light-grey; */
    font-family: 'GeneralSans-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.015em;
    text-align: left;
}

.pagament-row:last-child {
    border-bottom: none;
}

.pagament-title {
    background-color: $light-grey-2;
    
}

.pagament-last-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 32px;
}

.field {
    font-family: 'GeneralSans-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.015em;
    text-align: left;
}

.horizontal {
    font-family: 'GeneralSans-Semibold';
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.015em;
    text-align: left;
}

.warning-info {
    background-color: $yellow-warning;
    padding: 25px;
    max-width: 496px;
    border-radius: 42px;
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 24px;
}