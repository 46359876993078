@import "../_variables.scss";

.base-selector {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 20px;
  line-height: 22px;
  height: 100%;

  .selected-icon {
    display: none;
  }

  .name {
    width: 100%;
    padding-block: 0px;
    padding-inline: 0px;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #2B2A29;

    .selected-icon {
      display: none;
    }

    .label-selector {
      font-family: 'GeneralSans-Medium';
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 8px;
      text-transform: none;

      &.white {
        color: white;
      }
    }

    .MuiSvgIcon-root {
      display: flex;
      align-items: center;
    }

    .MuiInput-root {
      cursor: pointer;
    }
  }
}

.selected-icon {
  min-width: 18px;
  min-height: 14px;
  margin-right: 12px;
}