@import "../_variables.scss";


.table-container{ 
  margin-top: 32px;
 &.grid-view{
  display: none;
 }
  display: block;
  @media screen and (max-width: $mobile-screen){
    display: none;
  }
}
  .MuiTableRow-root{
    vertical-align: top;
    padding-left: 5px;
    background-color: white;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    &.table-row {
      display: table-row;
      @media screen and (max-width: $mobile-screen){
        display: none;
      }
    }
    &.row-pro{
      background-color: $light-grey-2;
    }
  }
  .MuiTableCell-root{
    font-family: 'GeneralSans-Regular';;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.015em;
    padding:15px 8px;
  
    &:first-child{
      padding-left: 0;
    }
  
    &:last-child{
      padding-right: 0;
    }
  
    &.MuiTableCell-head{
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
      }
      font-family: 'GeneralSans-Regular';;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.015em;
      text-align: left;
      color: $primary-color;
      opacity: 85%;
      svg path{
        fill: $primary-color;      
      }
      .MuiTableSortLabel-icon{
        color: $primary-color;
        height: 24px;
      }
      padding:8px;
    }
  }


.table-container-mobile{
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  gap:16px;
  
  .table-row-mobile {
    position:relative;
    border: 0.5px solid #A6A6A6;
    min-width: 300px;
    width: 100%;
    flex: 1;
    padding: 0 16px 16px 16px;
    display: flex;
    flex-direction: column;

    &.row-pro{
      background-color: $light-grey-2;
    }

    .basic-chips {
      width: 100%;
      height: 40px;
    }
    .line {
      display:flex;
      align-items: start;
      gap:8px;
      margin: 16px 0 16px 0;
      svg{
        min-width: 24px;
      }
      p {
        margin: 0;
      }
    }
  }
  
  @media screen and (min-width: $mobile-screen){
    display: none;
    &.grid-view{
     display: flex;
    }
  }

  @media screen and (max-width: $small-mobile-screen){
    .table-row-mobile {
      min-width: 235px;
    }
  }
}