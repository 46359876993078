/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  display: none;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed !important;
  height: 100% !important;
  z-index: 2 !important;
  width: 400px !important;
  @media (max-width: 768px) {
    width: 100% !important;
  }
}

/* General sidebar styles */
.bm-menu {
  background-color: #eae9df;
  @media (max-width: 768px) {
    padding: 40px 0px;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  padding-top: 84px !important;
  @media (max-width: 768px) {
    padding-top: 0px !important;
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}



.sidebar-class{
  width: 400px !important;
  position: fixed !important; ;
  top: 70px !important;
  bottom: 70px !important;
  min-height: 0 !important; 
  background-color: #d9d7d9 !important;
  box-shadow: unset !important;
}

.Sidebar-Elements{
  .element-s{
    cursor: pointer;
    padding: 14px 25px;
    text-align: left;
    display: flex;
    align-items: center;
    svg{
      margin-right: 10px;
    }
    &.bottom{
      border-bottom: 1px solid #e1dfd7;
    }
  }
  .element-s:hover {
    background-color: #3d3338;
    color: white;
    transition: 0.5s ease all;
    
  }
  a{
    color: black
  }
  a:hover{
    color: white !important;
    text-decoration: none;
  }
}

.sidebar-class-root{
  left: unset !important;
  margin: 70px 0 40px 0;
}