@import "./_variables.scss";


.mobile-options-container {
    position: absolute;
    top: 64px;
    width: 100%;
    z-index: 10;


    @media screen and (min-width: $mobile-screen){
        display: none;
    }


    .large-header-section {        
        background-color: $accent-color;
    }
    
    .header-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0px 16px;
        border-top: 1px solid white;
        width: calc(100% - 32px);
        height: 63px;
    }
}
