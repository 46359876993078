@import "./_variables.scss";


.acreditacions {
    max-width: calc(100% - 24px - 24px);
}

.acreditacions-buttons {
    button {
        margin-top: 34px;
    }
}

.radio-button {
    .Mui-checked {
        color: $accent-color;
    }
}