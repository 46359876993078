@import "../_variables.scss";


.file-form-element {
    display: flex;
    flex-direction: column;
    gap: 12px;

    label {
        font-family:'GeneralSans-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 21.6px;
        text-align: left;
        display: flex;
        gap: 8px
    }

    .description {
        font-family: 'GeneralSans-Italic';
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 21.6px;
        text-align: left;
    }
}

.add-file-button {
    min-height: 56px;
    top: 520px;
    left: 168px;
    padding: 16px;
    gap: 8px;
    border-radius: 0px 0px 24px 0px;
    display: flex;
    justify-content: center;
    font-family: 'GeneralSans-Variable';
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}

.primary {
    background-color: $primary-color;
    color: white;

    &.outlined {
        color: $primary-color;
        border: 1.25px solid $primary-color;
        background-color: white;
    }
}

.secondary {
    background-color: $accent-color;
    color: white;

    &.outlined {
        color: $accent-color;
        border: 1.25px solid $accent-color;
        background-color: white;
    }
}

.light-grey {
    background-color: $accent-color;
    color: white;
}