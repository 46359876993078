@import "./_variables.scss";

.showDetail {
	width: 100%;
	margin-top: 10px;
	container-type: inline-size;

	.goBackButton {
		max-width: 1200px;
		margin: auto;
		padding: 0px 50px;
	}

	.showDetailContent {
		max-width: 1200px;
		padding: 0px 50px;
		margin: 70px auto;
	}

	.accordion {
		.accordionHeader {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin: 0px;
			padding: 0px;
			border: none;
			background-color: white;

			font-family: 'Aventa Variable';
			font-size: 20px;
			font-weight: 700;
			text-align: left;
			color: $accent-color;

			cursor: pointer;

			svg {
				path {
					fill: $accent-color;
				}
			}
		}

		.accordionPanel {
			margin: 20px 0px 70px 0px;
		}
	}

	.active {
		.accordionPanel {
			display: block;
		}
	}

	.disabled {
		.accordionPanel {
			display: none;
		}
	}

	.activeHeader svg {
		rotate: 180deg;
	}

	.topShowBox {
		background-color: #F0EBE899;
		margin-left: 10px;

		.topShowContentBox {
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			justify-content: space-between;
			gap: 20px;
			max-width: 1200px;
			padding: 0px 20px 40px 30px;
			margin: 50px auto 0px auto;


			.topShowContentBadges {
				display: flex;
				gap: 10px;
				position: absolute;

				.topBadge {
					min-width: 96px;
					max-width: 100px;
					height: 70px;
					padding: 5px;
					text-transform: uppercase;
					border-radius: 0px 0px 25px 0px;
					text-wrap: balance;
					filter: drop-shadow(0px 2px 5px rgba(43, 42, 41, 0.2));

					b {
						font-family: 'GeneralSans-Semibold';
						font-size: 13px;
					}

					/* @media (max-width: $mobile-screen) {
                            max-width: calc(100% + 1rem);
                            margin-left: -0.5rem;
                            margin-right: -0.5rem;
                            background-color: white;
                            display: flex;
                            flex-direction: column;
                            z-index: 1;
                        } */
				}
			}

			.topShowContent {
				display: flex;
				gap: 50px;
				// container-type: inline-size;

				.topShowContentDetails {
					margin-top: 100px;
					display: flex;
					flex-direction: column;
					gap: 20px;
					width: 50%;

					.topShowContentDetail {
						display: flex;
						flex-direction: column;
						gap: 5px;
					}

					.topShowContentDetailElement {
						display: grid;
						grid-template-columns: 24px auto 85px;
						align-items: center;
						gap: 20px;
						font-family: 'GeneralSans-Semibold';

						.iconSpace {
							width: 24px;
						}
					}
				}

				.topShowImgBox {
					display: flex;
					flex-direction: column;
					position: relative;
					gap: 10px;
					width: 50%;
				}
			}

			h1 {
				font-family: 'Aventa Variable';
				font-size: 48px;
				font-weight: 700;
				margin: 0;
				line-height: 62px;
			}

			h4 {
				margin: 0px;
			}
		}
	}
	.topShowContentButtonsMobile{
		display: none;
	}

	.topShowButtonsDesktop {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.shareBox {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 5px;

		svg {
			width: 24px;
		}
	}

	.descriptionAndArtisticSheetBox {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;

		.descriptionBox,
		.artisticSheet {
			max-width: 50%;
			min-width: 370px;
			flex: 1;
		}

		.colaboration {
			margin-top: 50px;
			display: flex;
			flex-direction: column;
			gap: 20px;
		}

		.artisticSheet p {
			margin-bottom: 20px;
		}
	}

	.mainShowBox {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		border-bottom: 1px solid #D6D0CD;
    padding: 20px 0;

		h4,h5{
			margin-top: 0;
		}

		.infoBoxWrapper,
		.accreditedPersons {
			max-width: 50%;
			flex: 1;
		}

		.accreditedPersons {
			min-width: 300px;
		}

		.infoBoxWrapper {
			min-width: 370px;
		}

		.infoBox {
			display: flex;
			flex-direction: column;
			background-color: #F0EBE899;
			border-radius: 0px 0px 25px 0px;
			padding: 20px;
			gap: 10px;

			svg {
				cursor: pointer;
			}

			.telAndEmail {
				display: flex;

				div {
					width: 50%;
				}
			}

			.infoBoxHeader,
			.infoBoxContent {
				border-bottom: 1px solid #D6D0CD;
			}

			.infoBoxHeader {
				display: flex;
				align-items: center;
				justify-content: space-between;

				div {
					display: flex;
					align-items: center;
					gap: 10px;
				}
			}

			.infoBoxContent {
				padding: 20px 0;

				div {
					margin-bottom: 10px;
				}
			}

			.socialMedia {
				display: flex;
				align-items: center;
				gap: 10px;

				svg {
					width: 24px;
				}
			}
		}

		.accreditedPersons {
			.accreditation {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 20px 10px;
				cursor: pointer;

				&:hover {
					background-color: $accent-color;
					color: white;

					button {
						color: white;
					}

					svg path {
						fill: white;
					}
				}
			}
		}
	}

	.carrousel-container {
		/* @media (max-width: $mobile-screen) {
          margin-left: -$spacer/2;
          margin-right: -$spacer/2;
        } */
		img {
			aspect-ratio: 4/3;
		}
		.slick-prev {
			z-index: 1;
			left: 10px !important;
		}

		.slick-next {
			z-index: 1;
			right: 10px !important;
		}

		.carrousel {
			&__item-container {
				position: relative;
				overflow: hidden;
				width: 100%;
				padding-top: 75%;

				iframe {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					height: 100%;
					width: 100%;
				}
			}
		}

		.mask {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(255, 41, 56, 0.6);
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			div {
				color: white;
				font-size: 2.625rem;
				font-weight: 600;
				letter-spacing: 0.03em;
				text-transform: uppercase;
			}
		}

		.mask-time-change {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;
			background-color: $primary-color;
			opacity: 0.9;
			height: 37px;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;

			@media (max-width: $mobile-screen) {
				bottom: 0;
				top: auto;
			}

			&__icon {
				display: inline-flex;
				flex-shrink: 0;
				margin: 0 0 0 20px;

				svg {
					height: 24px;
					width: 24px;
				}
			}

			&__label {
				color: $yellow-warning;
				font-size: 1.563rem;
				line-height: 1.875rem;
				font-weight: 600;
				text-transform: uppercase;
				letter-spacing: 0.03em;
				margin: auto;

				@media (max-width: $medium-screen) {
					font-size: 1.2rem;
				}
			}
		}
	}

	.separator {
		border-bottom: 1px solid #5C5958;
		margin: 0px;
		opacity: 1;
	}

	p {
		margin: 0;
	}

	h4,
	h5 {
		margin: 10px 0px;
	}

	.light {
		font-weight: 600;
		margin-right: 5px;
	}

	.bold {
		font-weight: 600;
	}

	.red {
		color: $accent-color;
	}

	.canceled {
		text-decoration: line-through;
		text-decoration-color: $accent-color;
	}

	.changed {
		color: $accent-color;
		font-weight: 600;
	}

	.change {
		display: flex;
		opacity: 0.9;
		align-items: center;

		&__arrow-left {
			width: 0;
			height: 0;
			border-top: 0.75rem solid transparent;
			border-bottom: 0.75rem solid transparent;
			border-right: 1rem solid $light-grey;
		}

		&__content {
			display: flex;
			align-items: center;
			background-color: $light-grey;

			&__icon {
				display: inline-flex;
				flex-shrink: 0;

				svg {
					height: 16px;
					width: 16px;
				}
			}

			&__label {
				color: $yellow-warning;
				font-size: 1rem;
				font-weight: 600;
				letter-spacing: 0.03em;
				padding: 0 10px;

			}
		}
	}


	// @MEDIA & @CONTAINER



	@container (width < 767px) {
		.goBackButton {
			padding: 0;
			width: 100% !important;
		}
		.topShowButtonsDesktop{
			display: none;
		}

		.topShowContentButtonsMobile {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@media (max-width: 400px) {
				align-items: flex-start;
				flex-direction: column;
				gap: 10px;
			}
		}

		.topShowBox {
			margin: 0;
			padding: 0px 10px;

			.topShowContentBox {
				padding: 0px 0px 40px 0px;
				gap: 0px;

				.topShowContentBox {
					width: 100%;
				}

				.topShowContentBadges {
					background-color: white;
					position: relative;
					max-width: calc(100% + 20px);
					flex-direction: column;
					gap: 0;

					.topBadge {
						max-width: 90% !important;
						height: auto !important;
						z-index: 1;
					}
				}

				.topShowContent {
					flex-wrap: wrap-reverse;
					gap:0;

					.topShowContentDetails {
						margin-top: 30px;
						width: 100%;
					}

					.topShowImgBox {
						width: 100%;

						/* max-width: calc(100% + 20px);
                        margin-left: -10px;
                        margin-right: -10px; */
					}
				}

			}
		}

		.showDetailContent {
			padding: 0px 10px;

			.manager,
			.accreditedPersons {
				max-width: 100% !important;
			}
		}
	}
}