.login-container {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    opacity: 0.8;
}

.Login {
    grid-column: 2;
    background-color: #EAE8DF;
    border-radius: 20px;
    padding: 48px 24px;
    display: grid;
    grid-template-columns: 1fr 2fr;

    .img-login {
        grid-column: 1;
    }

    .form-login-container{
        grid-column: 2;
    }

    .form-login {
        
        .input {
            width: 100%;
            height: 48px;
            margin-bottom: 16px;
            padding-left: 10px;
            color: #3D3338;
            background-color: #FFFFFF;
            border: 0px;
            border-radius: 10px;
        }

        .error{
            background-color: pink;
        }

        .hide-reset{
            display: none;
          }
          
          .show-reset{
            margin-top: 15px;
            visibility: visible;
            transition: opacity 1s linear;
          }
    }

    .login-button{
        width: 100%;
        height: 48px;
        background: #645C60 0% 0% no-repeat padding-box;
        border: 1px solid #FFFFFF;
        border-radius: 12px;
        opacity: 1;
        color: white;
        padding: 10px 30px;
        margin-bottom: 16px;
        transition: 1s all;
    }

    .login-button:hover{
        background: #E30715 0% 0% no-repeat padding-box;
    }

    .buttons-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        gap: 10px;
    }

    .forgotten-password{
        width: 100%;
        text-align: right;
    }
    .signup-complete{
        width: 100%;
        height: 20px;
        text-align: right;
        margin-top: 10px;
        font-weight: 600;
    }
}

@media only screen and (max-width: 768px) {

    .login-container {
        opacity: unset;
        grid-template-columns: unset;
    }

    .Login {
        border-radius: unset;
        display: unset;
        grid-column: unset;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr;
    
        .img-login {
            grid-column: unset;
            grid-row: 1;
        }

        .form-login-container{
            grid-column: unset;
            grid-row: 2;
        }

        .login-button{
            width: 100%;
        }
    }
}