$primary-color: #2B2A29;
$accent-color: #D60D1A;
$info-color: #273BF3;
$alert-color: #F3C627;
$correct-color: #27F39D;
$error-color: #80141C;

$light-grey: #D6D0CD;
$light-grey-2: #f6f3f1;
$light-grey-3: #F0EBE8;
$white:#ffff;

$dark-grey: #5C5958;

$yellow-warning: #F3C627;

$light-accent: #E90C1B;

$small-mobile-screen: 412px;
$mobile-screen: 756px;
$medium-screen: 1066px;
$large-screen: 1200px;

$normal-font-weight: 400;
$bold-font-weight: 600;
$extra-bold-font-weight: 700;

@mixin multiline-ellpisis($lines, $backgroundColor, $lineHeight) {
  display: block;
  position: relative;
  overflow: hidden;
  max-height: $lineHeight * $lines;
  background-color: $backgroundColor;
  line-height: $lineHeight;
  
  &:before {
    background-color: inherit;
    position: absolute;
    content: "…";
    padding-left: 0.2em;
    right: 0;
    top: $lineHeight * ($lines - 1);
  }

}