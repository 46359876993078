@import "../_variables.scss";

.select {
    gap: 8px;

    .label {
        font-family: 'GeneralSans-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 21.6px;
        text-align: left;
    }

    .MuiFormLabel-root.Mui-focused {
        color: $accent-color !important;
        width: 100% !important;
    }

    .MuiInputBase-root {
        min-height: 32px;
    }

    .MuiFormHelperText-root {
        position: absolute;
    }

    .MuiInput-underline::after {
        border-color: $accent-color !important;
    }
    .selected-field {
        font-family: 'GeneralSans-Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 21.6px;
        text-align: left;
        width: 100%;
    }
}



.Mui-error {
    color: $error-color !important;
}
