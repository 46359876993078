.selector-class{
  cursor: pointer;
  &.change-colors{
    background-color: #3D3338;
    color: white;
    padding: 0px 5px;
    border-radius: 10px;
    height: 40px;
    @media only screen and (max-width: 768px) {
      background-color: #645C60;
    }
  }
  .selector{
    svg{
      cursor: pointer;
    }
    display: flex;
    position: relative;
    height: 40px;
    align-items: center;
    justify-content: center;
    .separator{
      border-left: 1px solid grey;
      opacity: 0.5;
      margin: 0px 10px;
      height: inherit;
    }
    width: unset !important;
  }
  .options{
    width: 270px;
    position: absolute;
    background-color: #3d3338;
    min-width: 160px;
    padding: 12px 16px;
    z-index: 1;
    margin-top:22px;
    -webkit-transform: translateX(-44%);
    transition-timing-function: linear;
    transition-timing-function: cubic-bezier(0, 0, 1, 1);
    @media only screen and (max-width: 768px) {
      color: red;
      width: 100%;
      margin-top: unset;
      left: 50%;
      -webkit-transform: translateX(-50%);
    }
  }
  .options:after {
    content: '';
      position: absolute;
      left: 110px;
      top: -15px;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid #3d3338;
      clear: both;
   @media only screen and (max-width: 768px) {
    top: -10px;
    left: 50%;
    border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-bottom: 10px solid #3d3338;
  }

}
  .option{
    text-align: center;
    font-size: 16px;
    border-bottom: 1px solid rgba(128,128,128,0.2);
    padding: 5px;
    cursor: pointer
  }
  .option:last-child{
    border-bottom: unset;
  }
  .selected{
    background-color: rgba(255, 255, 255, 0.2)
  }
  .ripple{
    position: relative; /*Position relative is required*/
    height: 100%;
    width: 100%;
    display: block;
    outline: none;

    color: #fff;
    cursor: pointer;
    overflow:hidden;
  }
  
  .ripple:hover:before{
    animation: ripple 1.5s ease;
  }
  
  .ripple:before{
    content:"";
    position:absolute; top:0; left:0;
    width:100%; height:100%;
    background-color:rgba(255, 255, 255, 0.7);
    transform:scale(0);
  }
  
  @keyframes ripple{
    from{transform:scale(0); opacity:1;}
    to{transform:scale(2);opacity:0;}
  }
  .current-option{
    font-size: 15px;
    text-align: left;
    font-weight: 600;
  }
}

.selector-class-horitzontal{
  .options{
    display: flex;
    cursor: pointer;
    justify-content: center;
    .option{
      display: flex;
      align-items: center;
      &.selected{
        color: RED;
      }
      .option-structure{
        .name{
          font-size: 11px;
          font-weight: 400;
          justify-content: center;
          display: flex;
          text-transform: lowercase;
        }
        .day{
          font-size: 18px;
          justify-content: center;
          display: flex;
        }
      }
      &:not(:last-child):after {
        content: "";
        border-left: 1px solid #D9D7D8;
        display: flex;
        margin: 0 15px;
        height: 40px;
        @media only screen and (max-width: 768px) {
          border-left: none;
          margin: 0 7px;
        }
      }
    }
  }
}

.selector-class-horitzontal-filters {
  @media only screen and (max-width: 768px) {
    width: 110%;
  }
  background: #F5F4F0;
  padding: 10px 0;
  .options {
    display: flex;
    cursor: pointer;
    justify-content: center;
    .option{
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #8B8588;
      &.selected{
        color: RED;
      }
      &:not(:last-child):after {
        content: "";
        border-left: 1px solid #D9D7D8;
        display: flex;
        margin: 0 15px;
        height: 40px;
        @media only screen and (max-width: 768px) {
          margin: 0 7px;
          height: 30px;
        }
      }
    }
  }
}

