@import "./_variables.scss";
.table-container-mobile {  
	container-type: inline-size;

  .table-row-mobile {  
    &.artist-item {
      min-width: 146px;  
      flex:0 0 calc(16.6% - 15px);
      padding: 0;
      
      .artist-item-imatge{
        min-height: 220px;
        background-image: url("../img/professional-grid-default.png");
        background-color: $light-grey;
        background-position: center center;
        background-size: cover;

      }
      
      .artist-item-content{
        padding: 0 8px 8px 8px;
        display: flex;
        flex-direction: column;
      }    
    }    
  }

  @container (width < 1360px) {
    .table-row-mobile {  
      &.artist-item {
        flex:0 0 calc(25% - 15px);        
      }      
    }
  }

  @container (width < 1100px) {
    .table-row-mobile {  
      &.artist-item {
        flex:0 0 calc(33.33% - 15px);        
      }      
    }
  }

  @container (width < 767px) {
    .table-row-mobile {  
      &.artist-item {
        flex:0 0 calc(50% - 15px);        
      }      
    }
  }

  @container (width < 412px) {
    .table-row-mobile {  
      &.artist-item {
        flex:1 0 auto;    
      }      
    }
  }
}

.skeleton-text {
  height: 1.5rem;
  width: 70%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 1rem;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
