.Live-title{
  font-weight: 600;
  font-size: 24px;
  box-shadow: 10vw 0px 0px 0px #eae9df,-10vw 0px 0px 0px #eae9df;
  padding: 30px 0px;
  text-align: left;
  background-color: #eae9df;
}

.LIVE {
  font-family: roboto;
  // .current-stream{
  //   border-left: 5px solid #e30715;
  // }
  .player-small{
    div{
      
    max-width: 400px;
    max-height: 225px;
    @media only screen and (max-width: 768px) {
      max-width: 90vw;
    }
    }
    
  }
  .image{
    img{
      max-width: 400px;
      max-height: 100%;
      @media only screen and (max-width: 768px) {
        max-width: 90vw;
      }
    }
  }
  .text{
    background-color: #EAE8DF;
    text-align: left;
    padding: 30px 10px;
    box-shadow: 10vw 0px 0px 0px #eae9df,-10vw 0px 0px 0px #eae9df;
  }
  .directes{
    text-align: left;
    font-weight: 600;
    padding: 20px 0px;
    border-top: 3px solid #e30715;
  }
  .grid-name > div {
    flex: 1 1 200px;
  }
  .grid-name{
    gap: 12px;
    display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // grid-gap: 30px;
    // gap: 30px;
    // @media only screen and (max-width: 768px) {
    //     grid-template-columns: repeat(1, 1fr);
    //     grid-template-rows: repeat(3, 1fr);
    //     gap: 0px;
    // }
    margin-bottom: 20px;
  .name-container-fira{
    display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin-bottom: 10px;

    @media only screen and (max-width: 768px) {
    &:after {
      content: "";
    display: flex;
    border-bottom: 1px solid #d9d7d8;
    margin: 10px 0;
      }
    }
      text-align: left;
      .type-line {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .activity-type{
        display: flex;
        width: 50%;
        font-size: 10px;
        padding: 10px 0;
        font-weight: 600;
      }
      .access-info{
        display: flex;
        align-items: center;
        margin-left: unset;
        span {
          font-size: 12px;
        }
      }
      .bell-icon, .access-icon {
        display: flex;
        width: 50% !important;
        justify-content: flex-end;
        &.absolute {
          width: unset !important;
          position: absolute;
          right: 15px;
        }
      }
      .description-container {
        .access-info {
          margin-bottom: 5px;
          span {
            font-size: 12px;
            padding: 0 10px;
          }
        }
      }
      &.programation{
        padding: 0px 12px 0px 0px;
      }
      &.current-time{
        border-top: 2px solid #e30715;
      }
      .show-name{
        display: flex;
        align-items: center;
        &.no-margin{
          margin-left: unset;
        }
      }.type-line {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .activity-type{
      display: flex;
      width: 50%;
      font-size: 10px;
      padding: 10px 0;
      font-weight: 600;
    }
    .bell-icon, .access-icon {
      display: flex;
      width: 50% !important;
      justify-content: flex-end;
      &.absolute {
        width: unset !important;
        position: absolute;
        right: 15px;
      }
    }
    .description-container {
      .access-info {
        margin-bottom: 5px;
        span {
          font-size: 12px;
          padding: 0 10px;
        }
      }
    }
    &.programation{
      padding: 0px 12px 0px 0px;
    }
    &.current-time{
      border-top: 2px solid #e30715;
    }
    .show-name{
      display: flex;
      align-items: center;
      &.no-margin{
        margin-left: unset;
      }
    }.type-line {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .activity-type{
      display: flex;
      width: 50%;
      font-size: 10px;
      padding: 10px 0;
      font-weight: 600;
    }
    .bell-icon, .access-icon {
      display: flex;
      width: 50% !important;
      justify-content: flex-end;
      &.absolute {
        width: unset !important;
        position: absolute;
        right: 15px;
      }
    }
    .description-container {
      .access-info {
        margin-bottom: 5px;
        span {
          font-size: 12px;
          padding: 0 10px;
        }
      }
    }
    &.programation{
      padding: 0px 12px 0px 0px;
    }
    &.current-time{
      border-top: 2px solid #e30715;
    }
    .show-name{
      display: flex;
      align-items: center;
      &.no-margin{
        margin-left: unset;
      }
    }
    }
}
}