.Xat{
  display: flex;
  height: inherit;
  .xats-container{
    width: 30%;
    padding: 20px 10px;
  }
  .xats-container-mobile{
    width: 100%;
  }
  .xat-element{
    width: 70%;
    padding: 20px 10px;
    .xat-header{
      height: 40px;
      display: flex;
      align-items: center;
      .xat-avatar{
        padding: 10px;
      }
      .xat-title{
        font-weight: 600;
      }
    }
    .xat-container {
      height: 80vh;
      display: flex;
      flex-direction: column;
      position: relative;

      .title-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 10%;
        font-size: 12px;
        margin-bottom: 15px;
        border-bottom: 1px solid black;

        .title-text {
          display: flex;
          padding-bottom: 5px;
          width: 100%;
        }
        .name {
          font-weight: 600;
        }
      }
      
      .xat-messages{
        height: 80%;
        overflow: auto;
        flex-direction: column-reverse;
        display: flex;
        @media only screen and (max-width: 768px) {
          height: 300px;
          overflow-y: scroll;
        }
        .date-small{
          font-size: 8px;
          opacity: 0.8;
        }
        &.empty{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
    font-weight: 600;
          svg{
            font-size: 50px;
            fill: #bbbbbc;;
          }
        }
      }

      .form-container {
        height: 10%;
      }

      .form-msg{
        padding: 20px;
        border-radius: 10px;
        background-color: #EAE8E0;
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        width: 100%;
        position: absolute;
        margin-top: 20px;
        justify-content: space-between;
        .styled-input{
          width: 90%;
        }
      }

    }
    
  }
}

.xats-container-mobile{
  .form-msg{
    padding: 20px;
    border-radius: 10px;
    background-color: #EAE8E0;
    display: flex;
    /* margin-top: 20px; */
    justify-content: space-between;
    width: 100%;
    box-shadow: 10vw 0px 0px 0px #EAE8E0, -10vw 0px 0px 0px #EAE8E0;
    .styled-input{
      width: 90%;
    }
  }
  .title-container-mobile{
    display: flex;
  }
  .xats-list{
    @media only screen and (max-width: 768px) {
      height: 100%;
      overflow: unset;
      padding:unset;
    }
  }
  .xat-container{
    .xat-messages{
      height: 300px;
      flex-direction: column-reverse;
      display: flex;
      overflow-y: scroll;
      @media only screen and (max-width: 768px) {
        min-height: 300px;
        height: unset;
        overflow-y: unset;
      }
      .date-small{
        font-size: 8px;
        opacity: 0.8;
      }
      &.empty{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        svg{
          font-size: 50px;
          fill: #bbbbbc;;
        }
      }
    }
  }
}

.form-container-mobile{
  .form-msg{
    display: flex;
    margin-top: 20px;
  }
  .styled-input{
    width: 80%;
  }
  .rce-button{
    margin-left: auto;
  }
}



.xats-list{
  height: 80vh;
    overflow-y: auto;
    
    padding: 0px 10px;
  .title-container {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 10px 0 20px 0;
    font-size: 18px;
    font-weight: 600;
  }

  .xat-list-element{
    
    border-radius: 10px;

    display: flex;
    padding: 10px;
    cursor: pointer;
    margin-left: auto;
    align-items: center;
    margin-bottom: 5px;
    background-color: #F5F4F0;
    &.selected{
      background-color: #3D3338;
      color: white;
    }
    @media only screen and (max-width: 768px) {
      margin: 5px 0px;
    background-color: unset;
    border-bottom: 1px solid rgba(128,128,128,0.2);
    border-radius: 0px;
    }
    .xat-list-image{
      .image{
        width: 50px;
        /* background-image: url(w3css.gif); */
        /* background-repeat: no-repeat; */
        /* background-attachment: fixed; */
        background-position: center;
        height: 50px;
        /* background-color: #8B8588; */
        border-radius: 100%;
        /* padding: 5px; */
        margin-right: 10px;
      }
      img{
        width: 60px;
        height: 60px;
        border-radius: 100%;
        margin-right: 20px;
      }
    }
    .xat-list-info{
      display: flex;
      flex-direction: column;
      font-size: 12px;
      div {
        text-align: left;
      }
      .name {
        font-weight: 600;
      }
      .date {
        font-size: 10.5px;
        padding-top: 7px;
      }
    }
    .pending-container{
      background-color: #FA8E28;
      color: white;
      font-weight: 600;
      border-radius: 100%;
      width: 20px;
      height: 19px;
      margin-left: auto;
    }
  }
}

.bubble-container{
  width: 100%;
  padding: 3px 10px;
.bubble-text{
  background-color: #F5F4F0;
  font-size: 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  text-align: left;
  padding: 7px 17px;
  width: 55%;
  @media only screen and (max-width: 768px) {
    width: 80%;
  }
  &.left{
    border-top-left-radius: 0px;
    float: left;
  }
  &.right{
    border-top-right-radius: 0px;
    float: right;
  }
  // animation-fill-mode: forwards; 
  // animation-duration: 1s;
  // animation-name: createBox;
  // position: relative;

  // @keyframes createBox{
  //     0%{
  //       opacity: 1;
  //         width: 10%;

  //     }
  //     100%{
  //       opacity: 1;
  //         width: 60%;
  //     }
  //   }
  }
}

.svg-icon-class{
  width: 50px;
  height: 50px;
  background-color: #8B8588;
  border-radius: 100%;
  padding: 5px;
  margin-right: 10px;
  fill: #D9D7D8
  
}