#StepInit {
  text-align: left;
  .init-container {
    display: flex;
    flex-wrap: wrap;
    margin-top:20px;
    &-left {
      width: 100%;
      text-align: left;
      margin-bottom:20px;
    }
    &-right {
      width: 100%;
    }
  }
  h2 {
    font-size: 28px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  .buttons-options {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3d3338;
    color: #fff;
    padding: 15px;
    border: 1px solid #F5F4F0;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;

  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #3d3338;
    padding: 15px;
    border: 1px solid #F5F4F0;
    width: 100%;

    &:hover{
      background: #c8c8c8;
    }
  }
  h3 {
    font-size: 24px;
  }
  .separator {
    border-bottom: 1px solid;
    opacity: 0.2;
    margin-bottom: 15px;
  }
  .regulation-text{
    cursor: pointer;
    text-decoration: underline;
    font-size: 24px;
    font-weight: bold;
    margin-right: 5px;
  }
}

#PropostaItem {
  .proposta-any {
    font-size: 16px;
    color: red;
  }
  .proposta-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #F5F4F0;
  }
  .table-header {
    display: flex;
    padding: 10px 15px;
    background-color: #F5F4F0;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @media (max-width: 540px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-text{
      display: flex;
      justify-content: flex-start;
      align-items: center; 
    }

    h4 {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
    &-show {
      font-weight: 300;
      color: red;
      
    }
    &:hover {
      .table-header-show {
        text-decoration: underline;
      }
    }
  }
  .table-body {
    tr {
      font-weight: 300;
      height: 30px;
      width: 100%;
    }
    td {
      padding: 0px 15px;
      width: 33.33333333%;
      @media (max-width: 540px) {
        width: 50%;
      }
    }
  }
  .accio-presentar {
    font-size: 80%;
    border: 1px solid red;
    background: red;
    color: white;
    display: inline-block;
    margin: 1em;
    padding: 0.5em;
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: bold;
    &:hover {
      background: #fff;
      color: red;
    }
    @media (max-width: 540px) {
      padding-left: 0px;
      margin-left: 0px;
    }
  }
  .estat-proposta {
    font-size: 80%;
    border: 1px solid #E0C050;
    background: #fff;
    color: #E0C050;
    display: inline-block;
    margin: 1em;
    padding: 0.5em;
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: bold;
    @media (max-width: 540px) {
      padding-left: 0px;
      margin-left: 0px;
    }
  }
}

#EntitatItem {
  .entitat-any {
    font-size: 16px;
    color: red;
  }
  .entitat-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px 0;
  }
  .entitat-content {
    display: flex;
    flex-direction: column;
    width: 25%;
    padding: 5px;

    @media (max-width: 992px) {
      width: 33.3333333%;
    }

    @media (max-width: 768px) {
      width: 50%;
    }

    @media (max-width: 540px) {
      width: 100%;
    }

    &:hover {
      .entitat-show {
        text-decoration: underline;
      }
    }
  }
  .entitat-box {
    border: 1px solid #F5F4F0;
    padding: 15px;
    cursor: pointer;
  }
  .entitat-box:hover {
    background-color: #F5F4F0;
    transition: 0.5s all;
  }
  .entitat-name {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    //height: 4em;
    overflow: hidden;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 35px;
  }
  .entitat-tag {
    font-size: 80%;
    border: 1px solid #777777;
    color: #777777;
    display: inline-block;
    margin: 0 3px 3px 0;
    padding: 3px;
    border-radius: 4px;
    text-transform: capitalize;
  }
  .entitat-show {
    color: red;
    font-weight: 300;
    margin-top: 10px;
  }
}

.Proposta {
  display: block;
  text-align: left;
  margin-bottom: 100px;
  .page-title {
    font-size: 33px;
    font-weight: 700;
    margin-bottom: .5rem;
  }
  .separator-line {
    border-bottom: 1px solid;
    opacity: 0.2;
    margin-bottom: 15px;
  }
  .separator {
    height: 50px;
    position: relative;

    h4.centre-line {
      text-align: center;
      position: absolute;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }
  
    h4.centre-line:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1.5px;
      top: 50%;
      left: 0;
      z-index: -1;
      background: #EAE8DF;
    }
    
    h4.centre-line span {
      background-color: #fff;
      padding: 1rem;
      display: inline-block;
      font-weight: 300;
    }
  }
  .stepsbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 30px 0;

    &-step {
      color: #4d4d4d;
      background-color: #e5e5e5;
      border-color: #b2b2b2;
      padding: 6px 12px;
      font-weight: 700;
      text-shadow: 0 1px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      border: 1px solid transparent;
      margin-left: 5px;
  
      &.inactive {
        color: #4d4d4d;
        background-color: #e5e5e5;
        border-color: #b2b2b2;
      }

      &.active {
        color: #fff;
        background-color: #c1392b;
        border-color: #972d22;
      }
    }
  }

  .field {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    &-name {
      font-weight: 700;
      margin-right: 5px;
    }
    &-value {}
  }
}
