@import "./_variables.scss";


.preguntesFrequents{
    margin: 42px 24px;
    width: 60%;

    .faqsHeader{
        margin-bottom: 50px;
    }

    .faqsList{
        .accordion {
            .accordionHeader {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin: 0px;
                padding: 0px;
                border: none;
                background-color: white;
    
                font-family: 'Aventa Variable';
                font-size: 20px;
                font-weight: 700;
                text-align: left;
                color: $accent-color;
    
                cursor: pointer;
    
                svg {
                    path {
                        fill: $accent-color;
                    }
                }
                
                p{
                    margin: 0px;
                    font-size: 20px;
                    font-weight: 700;
                }
            }
    
            .accordionPanel {
                margin: 20px 0px 30px 0px;
            }
        }
    
        .active {
            .accordionPanel {
                display: block;
            }
        }
    
        .disabled {
            .accordionPanel {
                display: none;
            }
        }
    
        .activeHeader svg {
            rotate: 180deg;
        }
    }

    h4{
        margin: 0px;
    }

    p{
        text-align: justify;
    }
}