.Footer {
  background-color: #3D3338;
  height: 64px;
  font-size: 14px;
  color: white;
  z-index: 3;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .item {
    display: flex;
    align-items: center;
    color: #d9d7d8;
    &.small {
      font-size: 12px;
    }
  }

  .phone {
    display: flex;
    align-items: center;
    height: 100%;
    &::after {
      content: "";
      border-left: 1px solid  #707070;
      margin: 0px 12px;
      height: 100%;
    }
  }
}

.Footer-mobile {
  background-color: #3D3338;
  font-size: 14px;
  color: white;
  z-index: 0;
  bottom: 0;
  width: 100%;

  .item {
    margin: 15px 0;
    color: #d9d7d8;
    &.small {
      font-size: 12px;
    }
    &:not(:last-child):after {
      content: "";
      display: flex;
      border-bottom: 1px solid #707070;
      padding-top: 15px;
    }
  }
}

.social-links {
  display: flex;
  justify-content: center;
  div {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin: 3px;
    background-color: #645C60;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &.facebook:hover {
      background-color: #3d5a99;
      transition: 0.5s all;
    }
    &.linkedin:hover {
      background-color: #0277b5;
      transition: 0.5s all;
    }
    &.youtube:hover {
      background-color: #ff0400;
      transition: 0.5s all;
    }
    &.instagram:hover{
      background-color: #e1316c;
      transition: 0.5s all;
    }
    &.twitter:hover{
      background-color: #1fa1f2;
      transition: 0.5s all;
    }
    &.whatsapp:hover{
      background-color: #25D366;
      transition: 0.5s all;
    }
    &.telegram:hover{
      background-color: #0088cc;
      transition: 0.5s all;
    }
  }
  &.white{
    div{
      color: white;
    }
}
}
