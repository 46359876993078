.App-link {
  color: #61dafb;
}

.link-text {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font: Black Italic 32px/32px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-weight: 700;
  font-size: 26px;
}


.link-container {
  overflow: hidden;
  cursor: pointer;
  position: relative;

  .text {
    padding: 24px 0px 0px 32px !important;
    font-size: 32px !important;
  }

  @media only screen and (max-width: 768px) {
    padding: unset !important;

    .text {
      padding: 32px 0px 0px 32px !important;
      font-size: 24px !important;
      font-weight: 300;
    }
  }

  a:hover {
    color: white !important;
    text-decoration: none !important;
  }
}

.small-mobile {
  padding: 3px 15px;

  .home-link {
    border-radius: 15px !important;
    height: 60px !important;

    .text {
      display: flex;
      align-items: center;
      height: inherit;
      padding: 0px 20px;
      font-size: 20px !important;
    }
  }
}

.link-container:hover .programation-link,
.link-container:focus .programation-link {
  transform: scale(0.98);
}

.link-container:hover .direct-link,
.Landing-page .link-container:focus .direct-link {
  transform: scale(0.98);
}

.Landing-page {
  padding: 9vw 0px;

  .title-login {
    text-shadow: 1px 1px #3d3338;
    color: white;
    font-size: 24px;
    margin: 20px 0px;
  }
}

@media only screen and (max-width: 768px) {
  .Landing-page {
    padding: unset;
    box-shadow: 4vw 0px 0px 0px #eae9df, -4vw 0px 0px 0px #eae9df;

    .title-login {
      text-shadow: 1px 1px #3d3338;
      color: black;
      background-color: #EAE8DF;
      font-size: 24px;
      padding-top: 25px;
      margin: unset;
    }
  }
}

// .Login{
//   border: 1px solid black;
//   padding: 20px;
// }

.link-container:hover .professional-link,
.link-container:focus .professional-link {
  transform: scale(0.98);
}

.professional-link {
  &.disabled {
    opacity: 0.5;
    display: none;
    transform: unset !important;
  }
}

.programation-link {
  &.disabled {
    opacity: 0.5;
    display: none;
    transform: unset !important;
  }
}


.programation-link {
  transition: all 0.8s;
  height: inherit;
  background-color: #3D3338;
  /* fallback color */
  background-position: center;
  background-size: cover;
}

.direct-link {
  transition: all 0.8s;
  height: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.professional-link {
  transition: all 0.8s;
  height: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.Home {
  padding: 26px 0px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    padding: unset;
  }

  .separator-home {
    margin-top: 30px;

    @media only screen and (max-width: 768px) {
      margin-top: 5px;
    }
  }
}

.home-link {
  height: 500px;
  border-radius: 10px;
  background-color: #3D3338;
  text-shadow: 1px 1px #3d3338;

  &.light {
    background-color: #645C60;
  }

  &.lighter {
    background-color: #8B8588;
  }

  &.small {
    height: 195px;
  }

  &.big-light {
    text-shadow: none;
    height: 700px;

    .text {
      font-weight: 300;
      padding-right: 35% !important;
      word-break: break-word;

      @media only screen and (max-width: 768px) {
        padding: 20px !important;
      }
    }
  }

  &.big-dark {
    text-shadow: none;
    height: 700px;

    .text {
      font-weight: 300;
      padding-right: 35% !important;
      word-break: break-word;
      color: #3d3338;

      @media only screen and (max-width: 768px) {
        padding: 20px !important;
      }
    }

    .subtitle {
      padding: 2% 4% !important;
      text-align: left;
      font-weight: 400;
      line-height: unset;
      color: white;
      background-color: rgba(0, 0, 0, 0.075);

      @media only screen and (max-width: 768px) {
        padding: 20px !important;
      }
    }

    .text-card-hightligh {
      line-height: unset;
      color: white;
      font-weight: bold;
      padding: 2% 4% !important;
      background-color: rgba(0, 0, 0, 0.075);
      font-size: 36px !important;
      text-transform: uppercase;
      @media only screen and (max-width: 768px) {
        padding: 20px !important;
      }
    }

    .encapsulate {
      padding: 15px 30px;
    }

    .propostes-link {
      height: 60%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    button {
      border-radius: 2.5px;
      background-color: #3D3338;
      color: #fff;
      border: 0;
      font-weight: bold;
      padding: 10px 50px;
      margin-top: 15px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 280px;
    border-radius: unset;
  }

  .text {
    align-items: center;
    display: flex;
    padding: 20px;
    color: white;
    font-size: 30px;
    text-align: left;
    line-height: 35px;

    svg {
      margin-right: 15px;
    }
  }
}

.home-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  &-big {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    gap: 20px;
  }

  @media only screen and (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.home-grid-lower {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  @media only screen and (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {

  .home-grid,
  .home-grid-big {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 0px;

    &-big {
      grid-template-rows: repeat(2, 1fr);
    }

    &-unique {
      grid-template-rows: 1fr;
    }
  }

  .home-grid-lower {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 10px;
  }
}