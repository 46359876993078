@import "../_variables.scss";

.link {
    font-family: 'GeneralSans-Light';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-align: left;
    text-decoration: none;
    cursor: pointer;

    &.primary-link {
        color: $primary-color;
    }
    
    &.secondary-link {
        color: $accent-color;
    }
}
