@import "./_variables.scss";

.navbar-menu {
    line {
        @media screen and (max-width: $medium-screen){
            display: none;
        }
    }

    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: $accent-color;
    height: 48px;
    padding: 12px;
    padding-left: 20px;
    padding-right: 20px;
    
    @media screen and (max-width: $mobile-screen){
        height: 64px;
    }

    .area-pro {
        font-family: Aventa;
        font-size: 16px;
        font-weight: 800;
        line-height: 22.96px;
        text-align: left;
    }

    .fira-med {
        font-family: Aventa;
        font-size: 16px;
        font-weight: 600;
        line-height: 22.96px;
        text-align: left;

        @media screen and (max-width: $medium-screen){
            display: none;
        }
    }

    .logo {
        color: white;
        display: flex;
        align-items: center;
        font-family: 'Aventa';
        
        p {
            margin: 0;
            margin-left: 12px;
            margin-right: 12px;
        }        
    }

    .right-bar {
        display: flex;
        align-items: center;
        gap: 20px;

        .user-options {
            display: flex;
            flex-direction: row;
            align-items: center;
            @media screen and (max-width: $mobile-screen){
                display: none;
            }
        }
    }

    .mobile-menu {
        cursor: pointer;
        @media screen and (min-width: $mobile-screen){
            display: none;
        }
    }
}
