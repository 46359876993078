@import "./_variables.scss";
.meet-tabs{
  min-height:auto;
  transform: translateY(1px);
  .MuiTabs-indicator{
    display: none;
  }
  .meet-tab {
    padding: 8px 16px;
    font-family: 'GeneralSans-Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-align: center;
    min-height:auto;
    &.Mui-selected{
      font-weight: 600;
      color: $primary-color;
      background-color: $light-grey-2;
      border: 1px solid #A6A6A6;
      border-bottom: 1px solid white;
    }
    .MuiTouchRipple-root{
      display: none;
    }
  }
  @media screen and (max-width: $mobile-screen) {
    flex-basis: 100%;
    .meet-tab {
      padding: 8px 32px;
    }
  }
}

.meeting-legend{
  font-family: 'GeneralSans-Regular';
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.015em;
  text-align: left;
  display: flex;
  align-items: center;  
  margin-left: 16px;
  padding: 10px 0;  
  .square-color{
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }
  &.free-time .square-color{
    background: $correct-color;
  }
  &.busy-schedule .square-color{
    background: #FF2938BF;
  }
  &.scheduled-schedule .square-color{
    background: $info-color;
  }

  @media screen and (max-width: $mobile-screen) {
    margin-bottom: 20px;
    margin-left: 0px;
  }
}
.meeting-container {
  border: 1px solid #A6A6A6;
  padding: 24px;
  @media screen and (max-width: $mobile-screen) {
    padding: 16px; 
  }
}
.meeting-item {
  p{
    margin: 0;
  }
  display: flex;
  padding: 24px;
  gap: 70px;
  flex-wrap: wrap;
  .meeting-item-content{
    flex: 1;
    a{
      color: $accent-color;
    }
    .meeting-item-content-image-bloc{
      display: flex;
      gap:16px;
      .meeting-item-content-image-bloc-subtitle{
        color: $dark-grey;
        font-weight: 400;
      }
    }
    .meeting-item-content-area-bloc{
      padding-left: 72px;
    }
    .meeting-item-content-divider{
      width: 100%;
      height: 0.5px;
      background-color:#A6A6A6;
      margin: 16px 0;
    }
    .meeting-item-content-area-bloc-wrapper{
      display: flex;
      align-items: center;
      gap:11px;
      flex-wrap: wrap;
      margin-top: 8px;
      div{
        cursor: pointer;
        border: 1px solid $dark-grey;
        padding: 0px 8px 0px 8px;
        border-radius: 32px;
        color: $dark-grey;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.015em;        
      }
    }
  }
  .meeting-item-times{ 
    border: 1px solid #A6A6A6;
    border-radius: 0px 0px 26px 0px;   
    padding: 16px 40px 24px 24px;
    max-width: 200px;
    .meeting-item-desc{
      margin-bottom: 16px;
    }
    .meeting-item-time{
      margin: 8px 0;
      background-color: $correct-color;
      display: flex;
      gap: 8px;
      padding: 8px 16px;
      font-weight: 600;
      &.busy{
        background-color: #FF2938BF;
      }
      &.scheduled{
        background: $info-color;
        color: white;
      }
    }
  }


  @media screen and (max-width: $mobile-screen) {
    padding: 24px 0;
    gap: 24px;
    .meeting-item-content{
      flex-basis: 100%;
      .meeting-item-content-area-bloc{
        padding-left: 0px;
      }
    }
    .meeting-item-times{
      padding: 16px 8px 24px 8px;
      flex-basis: 100%;
      max-width: 100%;
      display: flex;
      column-gap: 8px;
      row-gap: 16px;
      flex-wrap: wrap;

      .meeting-item-desc{
        margin-bottom: 0;
      }

      .meeting-item-time{
        padding: 16px 10px;
        flex-basis: calc(50% - 24px);
        margin: 0;
      }
    }
  }
}