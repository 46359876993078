.accept-regulation-wrapper{
    .accept-regulation {
        //styleName: Body 2;
        font-family: 'GeneralSans-Regular';
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.015em;
        text-align: left;
        max-height: 200px;
        max-width: 800px;
        
    }
    .checkbox{
        margin-top: 16px;
    }

}