@import "./_variables.scss";


.info-tarifes {
  border: 1px solid $light-grey;
  border-radius: 0px 0px 26px 0px;
  margin-bottom: 24px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    cursor: pointer;

    &.open {
      border-bottom: 1px solid $light-grey;
    }
  }

  article {
    padding: 1rem;
  }
}