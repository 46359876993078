@import "../_variables.scss";

.user-options-selector {
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 20px;
  line-height: 22px;

  .first-letter-round {
    background-color: white;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: $accent-color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;

    .letter {
      font-family: 'GeneralSans-Semibold';
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.02em;
    }
  }

  .name {
    font-family: 'GeneralSans-Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.015em;
    text-align: left;
    color: white;
    text-transform: none;

    .MuiSvgIcon-root {
      color: white;
      display: flex;
      align-items: center;
      height: 100%;
    }

    .MuiSelect-nativeInput {
      margin-left: 12px;
    }
  }
}

.user-options-selected-icon {
  margin-right: 12px;
}