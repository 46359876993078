@import "./_variables.scss";

.Form {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .fragment {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 64px;
    grid-row-gap: 24px;
    width: 100%;
    margin-bottom: 60px;

    @media screen and (max-width: $mobile-screen) {
      display: flex;
      flex-direction: column;
    }

    .fragmentColumn{
      display: flex;
      flex-direction: column;
      gap:24px;
    }

    .MuiFormControl-root {
      width: 100%;

      .MuiFormLabel-asterisk {
        color: $accent-color;
      }
    }

    .asterisk {
      color: $accent-color;
    }

    .description {
      margin-left: 8px;
      font-size: 14px;
    }
  }

  h4 {
    margin: 0;
    text-transform: lowercase;
  }

  h5 {
    margin: 0;
  }

  h4::first-letter,
  h5::first-letter {
    text-transform: capitalize;
  }
}

.header-add-accreditations {
  color: $accent-color;
}

.modalInfoAccreditation {
  svg path {
    fill: blue;
  }
}

.acreditacio {
  border-radius: 0px 0px 26px 0px;
  border: 1px solid $light-grey;
  padding: 28px;
}


//ProposalForm
.Propostes {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 25px;

  .selectedEntities {
    display: flex;
    align-items: center;
    width: 448px;
    height: 80px;
    margin-bottom: 25px;
    border-radius: 0px 0px 25px 0px;
    border: solid 1px $light-grey;

    div {
      margin: 0px 15px;
    }

    p {
      margin: 0px;
    }

    p:nth-child(1) {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }

    .separatorVertical {
      width: 1px;
      height: 80%;
      background-color: black;
      opacity: 0.2;
    }
  }

  .accordion {
    .accordionHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      margin: 0px;
      padding: 0px;
      border: none;
      background-color: white;

      font-family: 'Aventa Variable';
      font-size: 20px;
      font-weight: 700;
      text-align: left;
    }

    .accordionPanel {
      margin: 20px 0px;
    }

    .economicLinks {
      margin-bottom: 30px;
      margin-top: 30px;
      gap: 10px;

      .Form-element {
        display: flex;
        align-items: end;
        width: 528px;
        gap: 10px;

        textarea {
          height: 30px;
          min-width: 80%;
        }

        .Form-textarea {
          border: none;
          border-bottom: 1px black solid;
        }
      }
    }

    .file-form-element {
      max-width: 426px;

      label {
        display: flex;
        flex-direction: column;
      }
    }

    .accept-regulation {
      width: 808px;
      max-height: 500px;
    }
  }

  .separator {
    position: relative;
    right: 25px;
    width: calc(100% + 50px);
    height: 1px;
    background-color: black;
    opacity: 0.2;
    margin: 0;
  }

  .active {
    .accordionPanel {
      display: block;
    }
  }

  .disabled {
    .accordionPanel {
      display: none;
    }

    color: $light-grey;
  }

  h4 {
    max-width: 744px;
    margin: 0;
  }
}

.Stand {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 25px;
}

.proposalFormDialog {
  .MuiPaper-root {
    width: auto;
    height: auto;
    gap: 20px;
    padding: 40px 100px 40px 50px;
    align-items: normal;
    color: $info-color;
  }

  p {
    color: $info-color;
  }

  svg {
    width: 56px;
    height: 56px;
  }
}

.checkbox {
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;
  }

  span {
    margin-left: 8px;
    max-width: 100%;
  }
}

//___________________

.Stand {
  .select {
    width: 100%;
  }
}

.footer-buttons {
  display: flex;
  gap: 25px;
  margin: 0px;
  margin-bottom: 24px;
}