
////SLIDER STYLES

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 20px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: -6px;
    bottom: -3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px #ccc solid; 
  }
  
  input:checked + .slider {
    background-color: #f1838a;
    
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    background-color: #e30715 !important;
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  



////SLIDER STYLES

/* The switch - the box around the slider */
.switch-offtype {
  @media only screen and (max-width: 768px) {
    width: 70%;
    margin: 20px 0px;
  }
  margin-left: auto;
  font-size:15px;
  position: relative;
  display: inline-block;
  width: 200px;
  height: 20px;
  
  input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #e30a15;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

}