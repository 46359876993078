@import "./_variables.scss";

.footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: $primary-color;
    padding: 1.7rem;
    border-top: 1px solid white;

    @media screen and (max-width: $medium-screen) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    p {
        font-family: 'GeneralSans-Variable';
        color: white;
        font-size: 16px;
    }

    .url {
        font-weight: 600;
    }

    .icons {
        display: flex;
        gap: 6px;
    }
}