// estils generics

.title-generic {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.subtitle-generic {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.account-class {
  .modal-class {
    width: 80%;
    padding: 0px 10vw;

    @media screen and (max-width: 768px) {
      padding: 0px 5vw;
    }
  }
}


.entity-name-account {
  background-color: #f5f4f0;
  box-shadow: 10vw 0px 0px 0px #f5f4f0, -10vw 0px 0px 0px #f5f4f0;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0px;
  font-size: 20px;
  height: 50px;
}

.account-1 {
  background-color: #EAE8DF;
  box-shadow: 10vw 0px 0px 0px #EAE8DF, -10vw 0px 0px 0px #EAE8DF;
  display: flex;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: wrap;
  padding: 20px 0px;
}

.justify-content {
  display: flex;
  justify-content: center;
  height: inherit;
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

a.ical-link {
  color: unset !important;
  display: flex;
  align-items: center;
  font-size: 20px;
}

a.ical-link:hover {
  color: white !important;
  text-decoration: none !important;
}

.unstyled-button {
  background-color: unset;
  border: unset;
}



.professional-separator {
  content: "";
  border-left: 1px solid #b4b1b2;
  margin: 0px 12px;
  height: 75%;
  opacity: 0.5;
}

.professional-separator-small {
  content: "";
  border-left: 1px solid #b4b1b2;
  margin: 0px 12px;
  height: 30px;
  opacity: 0.5;
}

.pulsate-svg {
  transform-origin: 50% 50%;
  animation-duration: 1.5s;
  animation-name: pulse;
  animation-iteration-count: infinite;
}

// .animate-svg:hover {
//   animation: none;
// }

@keyframes pulse {
  15% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.95);
  }

  30% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(1);
  }

  40% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(1.15);
  }

  55% {
    stroke-width: 2px;
    stroke-opacity: 0;
    transform: scale(1);
  }

  65% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.95);
  }

  75% {
    stroke-width: 2px;
    stroke-opacity: 0;
    transform: scale(1);
  }

  85% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(1.15);
  }

  100% {
    stroke-width: 3px;
    stroke-opacity: 1;
    transform: scale(0.95);
  }
}

.access-info {
  margin-left: 5px;

  svg {
    cursor: pointer;
    vertical-align: text-top;
  }
}

.filter-back {
  float: right;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    background-color: #3D3338;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 10vw 0px 0px 0px #3D3338, -10vw 0px 0px 0px #3D3338;
    text-align: center;
    color: white;
  }

}


.filter-back-search {
  float: right;
  min-width: 30vw;
  display: flex;

  @media only screen and (max-width: 768px) {
    width: 100%;
    float: unset;
    padding: 10px 0px;
  }
}


.access-infos-separator {
  border-right: 1px solid #645C60;
  width: 1px;
  margin: 0px 1px;
  height: 25px;
}

.fit-content {
  width: fit-content;
}

.w-100 {
  width: 100%;
}

.search-svg {
  position: relative;
  right: 20px;

  @media only screen and (max-width: 768px) {
    position: absolute;
  }
}

.d-none {
  display: none !important;
}

.d-grid {
  display: grid !important;
}

.cursor-pointer {
  cursor: pointer;
}

.color-error{
  color:#E30715;
}

.bold {
  font-weight: 600;
}

.notificated {
  height: 20px;
  width: 20px;
  margin-top: 5px;
  margin-bottom: 13px;
  background-color: #FA8E28;

  &.blue {
    background-color: #5B91F0;
  }

  border-radius: 50%;
  display: inline-block;
}

.notificated:after {
  content: '\0021';
  font-size: 15px;
  font-weight: 600;
}

.limited-desktop-height {
  height: 778px;

  @media only screen and (max-width: 768px) {
    height: unset;
  }
}

.arrow-right {
  cursor: pointer;
  position: relative;
  right: 50px;
  background: #eae8df;
  width: 32px;
  border-radius: 100%;

  &.picture {
    background: #eae8df 50%;
  }

  svg {
    padding-top: 3px;
  }

  @media only screen and (max-width: 768px) {
    right: unset;
  }

  transition: background 1s;
}

.arrow-right.active {

  background: #E30715;
}


.arrow-left {
  cursor: pointer;
  position: relative;
  bottom: 30px;
  width: 0;
  background: linear-gradient(to bottom, #eae8df 50%, #eae8df 100%);
  width: 60px;
  border-radius: 12px 12px 0px 0px;

  @media screen and (max-width: 768px) {
    float: right;
  }
}

.link-no-style {
  color: unset;
  text-decoration: none;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes fadeBackground {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

/* Standard syntax */
@keyframes fadeBackground {
  from {
    opacity: 0.2;
  }

  to {
    opacity: 1;
  }
}

@media print {
  .noprint {
    visibility: hidden;
  }
}

.btn-fira {
  padding: 6px 12px;
  font-weight: 700;
  text-shadow: 0 1px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  border: 1px solid transparent;
  margin-right: 5px;

  &-red {
    color: #fff;
    background-color: #c1392b;
    border-color: #972d22;
  }

  &-grey {
    color: #4d4d4d;
    background-color: #e5e5e5;
    border-color: #b2b2b2;
  }
}

.wrapper-empty-info {
  text-align: center;
  color: #E30715;
  padding-top: 20px;
  font-size: 20px;

  a {
    color: #E30715;
    text-decoration: underline;
  }
}

.overflow-scroll {
  overflow: scroll;
}