.checkbox {
    display: flex;
    gap: 8px;

    span {
        font-family: 'GeneralSans-Regular';
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.015em;
        text-align: left;
    }
}