.Show {
  .primary-info-show-container{
    background-color: #3D3338;
    box-shadow: 10vw 0px 0px 0px #3D3338,-10vw 0px 0px 0px #3D3338;
    display: flex;
    justify-content: space-between;
    padding: 45px 0px;
    .info-content{
      
      color: white;
      text-align: left;
      position: relative;
      max-width:30vw;
      width: 100%;
      
      span{
        display: block;
      }
      .info-small{
        font-size: 12px;
      }
      .info-big{
        margin-top: 10px;
        // font-size: 30px;
        font-size: 27px;
        .small{
          font-size: 16px;
        }
      }
      .type-activity{
        margin-top: 10px;
        background-color: #759540;
        padding: 10px;
        border-radius: 10px;
        font-weight: 600;
        max-width: fit-content;
      }
      .eix-activity{
        margin-top: 10px;
        background-color: #E30715;
        padding: 10px;
        border-radius: 10px;
        font-weight: 600;
        max-width: fit-content;
      }
      .ambit{
        font-size:16px;
        font-weight: 600;
        margin: 15px 0px;
      }
      .ticket-familiar{
        margin-top: 10px;
        background-color: #F0DB5B;
        padding: 10px;
        color: black;
        font-weight: 600;
        clip-path: polygon(0% 0%, 100% 0, 90% 50%, 100% 100%, 0% 100%);
        &.estrena{
          background-color: #A32EFA;
          color: white;
        }
        &.cancelat{
          background-color: #fff3d4;
          color: black;
        }
      }
      .estrena-activity{
        margin-top: 10px;
        background-color: #ff9606;
        padding: 10px;
        border-radius: 20px;
        width: fit-content;
        box-shadow: 2px 2px 15px 0px #ff9606;
      }
      .video-show{
        cursor: pointer;
        width: fit-content;
      }
      .notification{
        margin-top: 60px;
      }
    }
    .image-content{
      width: 40vw;
      min-height: 450px;
      max-height: 500px;
      background-size: cover;
      background-color: #3d3338;
    }
  }
  .secondary-info-show-container{
    padding: 23px 0px 55px 0px;
    min-height: 290px;
    display: flex;
    .secondary-info-show-container-left{
      width: 40vw;
      font-size: 14px;
      text-align: left;
    }
    .secondary-info-show-container-center{
      width: 20vw;
      margin-left: 20px;
      padding: 0px 10px;
      text-align:left;
    }
    .secondary-info-show-container-right{
      margin-left: auto;
      position: relative;
      .duration-container{
        background: #635c5f 0% 0% no-repeat padding-box;
        border: 1px solid #FFFFFF;
        border-radius: 12px;
        opacity: 1;
        color: white;
        padding: 10px 40px;  
        font-size: 20px;
        cursor: pointer;
      }
      .duration-container:hover{
        background: #E30715 0% 0% no-repeat padding-box;
      }
    }
    .share-container{
      display: flex;
      font-size: 14px;
      align-items: center;
      margin-top: 10px;
      right: 0;
    }
  }
  .ternary-info-show-container{
    min-height: 315px;
    background-color: #eae9df;
    padding: 15px 0px 63px 0px;
    box-shadow: 10vw 0px 0px 0px #eae9df,-10vw 0px 0px 0px #eae9df;
    .separator{
      margin-top: 20px;
    }
    .show-members{
      font-size: 20px;
      margin: 30px 0px 20px 0px;
      text-align: left;
      font-weight: 600;
      &.small{
        font-size: 16px;
      }
    }
    span{
      display: block;
      text-align: left;
    }
    .main-contant-container{
      width: 37vw;
      padding: 20px;
      background-color: #f5f4f0;
      .info-professional{
        
        text-align: left;
        .name{
          text-transform: capitalize;
          font-size: 20px;
          font-weight: 300;
          display: flex;
          .contact-icons{
            display: flex;
            cursor: pointer;
            margin-left: auto;
          }
        }
        .parent{
          font-size: 24px;
          cursor: pointer;
        }
      }
      .professional-separator-horizontal{
        margin: 20px 0px;
        border-top: 1px solid #B2AEB0;
      }
    }
    .secondary-contant-container{
      display: flex;
      flex-wrap: wrap;
      .secondary-contact{
        background-color: #f5f4f0;
        padding: 20px;
      }
      .show-item-container-grid{
        width: 37vw;
        margin-right: 1vw;
        height: 72px;
        margin-bottom: 20px;
        background-color: #f5f4f0;
        cursor: pointer;
        display: flex;
        border-radius: 10px;
      }
      .show-item-container-grid:hover{
        background-color: #635c5f;
        color: white;
      }
      
      .name-info{
        padding-left: 20px;
        align-items: center;
        display: flex;
      }
      .actions-info{
        margin-left: auto;
        padding: 20px;
        align-items: center;
        display: flex;
        * {
          margin: 0px 10px;
        }
      }
    }
    .social-links {
      justify-content: flex-start;
    }
  }
  .go-back-container {
    display: flex;
    cursor: pointer;
    padding: 10px 0px;
    background-color: #645C60;
    color: #F5F4F0;
    box-shadow: 10vw 0px 0px 0px #645C60, -10vw 0px 0px 0px #645C60;
  }  
}

.Show-mobile {
  .type-activity{
    margin-top: 10px;
    background-color: #759540;
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
    color: white;
  }
  .eix-activity{
    margin-top: 10px;
    background-color: #E30715;
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
    color: white;
  }
  .ambit{
    font-size:16px;
    font-weight: 600;
    margin-top: 15px;
    display: flex;
  }
  .ticket-familiar{
    display: flex;
    width: 70%;
    margin-top: 10px;
    background-color: #F0DB5B;
    padding: 10px;
    color: black;
    font-weight: 600;
    clip-path: polygon(0% 0%, 100% 0, 90% 50%, 100% 100%, 0% 100%);
    &.estrena{
      background-color: #A32EFA;
      color: white;
    }
  }
  .go-back-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 20px 0;
    color: white;
    background-color: #3D3338;
    box-shadow: 10vw 0px 0px 0px #3D3338, -10vw 0px 0px 0px #3D3338;
  }
  .primary-info-show-container {
    .image-container {
      height: 270px;
      width: 100%;
      box-shadow: 10vw 0px 0px 0px #3d3338,-10vw 0px 0px 0px #3d3338;
      img {
        height: inherit;
        width: inherit;
      }
      background-size: cover;
      background-color: #3d3338;
    }

    .title-container {
      padding: 25px 0px;
      background-color: #EAE8E0;
      box-shadow: 10vw 0px 0px 0px #EAE8E0,-10vw 0px 0px 0px #EAE8E0;
      text-align: left;
      position: relative;
      font-weight: 600;
      font-size: 12px;
      .artist {
        font-size: 18px;
        padding-top: 10px;
      }
      .name {
        font-size: 18px;
        font-weight: 400;
      }
    }

    .performance-container {
      padding: 25px 0px;
      background-color: #f5f4f0;
      box-shadow: 10vw 0px 0px 0px #f5f4f0,-10vw 0px 0px 0px #f5f4f0;
      text-align: left;
      position: relative;
      .acces-infos{
        margin-left: auto;
      }     
    }
  }

  .secondary-info-show-container {
    margin-top: 20px;
    text-align: left;
    .duration-container {
      background: #635c5f 0% 0% no-repeat padding-box;
      border: 1px solid #FFFFFF;
      border-radius: 10px;
      margin-top: 15px;
      margin-bottom: 15px;
      opacity: 1;
      color: white;
      padding: 10px 40px;  
      cursor: pointer;
      &:hover {
        background: #E30715 0% 0% no-repeat padding-box;
      }
      a {
        font-size: 14px;
        font-weight: 600;
        justify-content: center;
      }
    }
    
    

    .divider {
      &:after {
        content: "";
        display: flex;
        border-top: 1px solid #3D3338;
        opacity: 20%;
        margin: 20px 0px;
      }
    }

    .share-container {
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      align-items: center;
      @media screen and (max-width: 768px) {
        margin: 20px 0px;
      }
    }
  }

  .ternary-info-show-container{
    min-height: 315px;
    background-color: #EAE8E0;
    padding: 15px 0px;
    box-shadow: 10vw 0px 0px 0px #EAE8E0,-10vw 0px 0px 0px #EAE8E0;

    .show-members{
      font-size: 12px;
      padding: 20px 0;
      text-align: left;
      font-weight: 600;
    }
    span{
      display: block;
      text-align: left;
    }
    .main-contant-container {
      .info-professional{
        text-align: left;
        text-transform: capitalize;
        .name{
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          display: flex;
          .contact-icons{
            display: flex;
            margin-left: auto;
          }
        }
        .parent{
          font-size: 24px;
          cursor: pointer;
        }
      }
      .professional-separator-horizontal{
        margin: 12px 0px;
        border-top: 1px solid #b2aeb0;
      }
      
    }
    .secondary-contant-container{
      display: flex;
      flex-wrap: wrap;
      .secondary-contact{
        background-color: #f5f4f0;
        padding: 20px;
      }
      .show-item-container-grid{
        width: 37vw;
        margin-right: 1vw;
        height: 72px;
        margin-bottom: 20px;
        background-color: #f5f4f0;
        cursor: pointer;
        display: flex;
        border-radius: 10px;
        @media screen and (max-width: 768px) {
          width: 100%;
          background-color:unset;
          border-top: 1px solid #d0cdc7;
          border-radius: 0px;
          margin-bottom: 0px;
        }
      }
      .show-item-container-grid:hover{
        background-color: #635c5f;
        color: white;
      }
      
      .name-info{
        align-items: center;
        display: flex;
      }
      .actions-info{
        margin-left: auto;
        padding: 20px 0;
        align-items: center;
        display: flex;
        * {
          margin: 0px 10px;
        }
      }
    }
    
    .social-links {
      justify-content: flex-start;
    }
  }
}

.Shows{
  margin-bottom: 20px;
  .title{
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 30px;
    min-height: 35px;
  }
  .shows-filters-mobile{
    font-weight: 600;
    font-size: 14px;
    background-color: #EAE8DF;
    box-shadow: 10vw 0 0 0 #EAE8DF, -10vw 0 0 0 #EAE8DF;
    width: 100%;
    top: 40px;
    left: 0;
    z-index: 1;
    transition: transform .5s;
    transition-delay: .3s;
    .selector-options{
      display: flex;
      @media screen and (max-width: 768px) {
        box-shadow: 10vw 0 0 0 #f5f4f0, -10vw 0 0 0 #f5f4f0;
        background: #f5f4f0;
        width: 100%;
        justify-content: center;
        margin-bottom: 1px;
        &.change-colors{
          background-color: #635c5f;
          box-shadow: 10vw 0 0 0 #635c5f, -10vw 0 0 0 #635c5f;
        }
      }
    }
    &.scrolled{
      transform: translateY(-100%);
    }
    @media screen and (max-width: 768px) {
        display: block;
        height: auto;
    }
    input[type="text"]{
      display: block !important;
      border: 1px solid transparent !important;
      border-radius: 5px !important;
      width: 100%;
      opacity: 0.48;
      font-size: 14px;
      font-weight: 400;
      padding: 8px 5px;
    }
    input[type="text"]:focus {
      outline-width: 0;
    }
    .title{
      font-weight: 600;
      font-size: 18px;
      padding-bottom: 10px;
    }
    .selectors {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .selector {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 5px 0;
    }
  }
  .shows-filters{
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 20px;
    min-height: 35px;
    box-shadow: 10vw 0 0 0 #f5f4f0, -10vw 0 0 0 #f5f4f0;
    background: #f5f4f0;

    width: 100%;
    top: 42px;
    left: 0;
    z-index: 1;
    transition: transform .5s;
    &.scrolled{
      transform: translateY(-100%);
    }
    @media screen and (max-width: 768px) {
        display: block;
        height: auto;
    }


    input[type="text"]{
      display: block !important;
      border: 1px solid transparent !important;
      border-radius: 5px !important;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      padding: 8px 5px;
    }
    input[type="text"]:focus {
      outline-width: 0;
    }
    .title {
      padding: 30px 0;
    }
    .selectors{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      &.no-space{
        justify-content: unset;
      }
    }
    .selector {
      display: flex;
      width: 33.33%;
    }
    .selector-options{
      display: flex;
      width: 260px;
      @media screen and (max-width: 768px) {
        box-shadow: 10vw 0 0 0 #f5f4f0, -10vw 0 0 0 #f5f4f0;
        background: #f5f4f0;
        width: 100%;
      }
    }
  }
  .search-container{
    align-items: center;
    width: 100%;
  }
  .layout-grid-container{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .filter-container{
    text-align: right;
    display: flex;
    justify-content: space-between;
  }

  .filter-container-input{
    display: flex;
    .favourites-label{
      font-size: 14px;
    }
    .favourites-input{
      color: red;
      cursor: pointer;
    }
    
    input[type='radio']{
     border: 1px;
    }
    input[type='radio']:checked:after {
      width: 15px;
      height: 15px;
      border-radius: 15px;
      top: -6px;
      left: -1px;
      @media screen and (max-width: 768px) {
        top: -2px;
      }
      position: relative;
      background-color: #e30a15;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 3px solid white;
    }
    @media screen and (max-width: 768px) {
      align-self: flex-end;
      margin:20px 0px;
    }
  
  }
  .name-info{
    width: 100%;
    align-items: center;
    display: flex;
  }
  .actions-info{
    padding: 20px;
    align-items: center;
    display: flex;
    * {
      margin: 0px 10px;
    }
    @media screen and (max-width: 768px) {
      padding: 20px 0px;
    }
  }
  .show-item-container{
    border-bottom: 1px solid #d2d2d2;
    &.current-time{
      border-top: 2px solid #e30715;
    }
    .inner{
      margin: 5px 0px;
      padding: 0px 12px;
      min-height: 68px;
      max-height: 85px;
      text-align: left;
      cursor: pointer;  
      display: flex;
      align-items: center;
      justify-content: space-between;
      .inner-col {
        display: flex;
        width: 50%;
      }
      .name-container{
        display: flex;
        width: 100%;
      }
      .access-info{
        padding: 0px 7px 0px 7px;
        align-items: center;
        height: 55px;
        display: inline-flex;
        span {
          font-size: 12px;
          padding: 0 5px;
        }
        &:before {
          content: "";
          border-left: 2px solid #d9d7d8;
          height: 40px;
          margin-right: 10px;
        }
      }
      .bell-icon{
        padding: 0px 7px 0px 7px;
        display: flex;
        align-items: center;
        height: 55px;
        width: 10%;
        &:before {
          content: "";
          border-left: 2px solid #d9d7d8;
          height: 40px;
          margin-right: 10px;
        }
      }
      .access-icon {
        padding: 0px 7px 0px 7px;
        display: flex;
        align-items: center;
        height: 55px;
        justify-content: flex-end;
        &:before {
          content: "";
          border-left: 2px solid #d9d7d8;
          height: 40px;
          margin-right: 10px;
        }
      }
      .activity-type{
        height: inherit;
        display: flex;
        align-items: center;
        font-size: 12px;
        width: 150px;
        font-weight: 600;
        justify-content: flex-end;
        text-align: right;
        height: -webkit-fill-available;
        &:after {
          content: "";
          border-left: 2px solid #d9d7d8;
          height: 40px;
          margin-left: 15px;
        }
      }
    
      .show-name{
        //white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        padding-left: 15px;
        &.no-margin{
          margin-left: unset;
        }
        @media screen and (max-width: 768px) {
          width: 60vw;
          white-space: unset;
          overflow: unset;
        }
      }
      // .notification-button{
      //   margin-left: auto;
      // }
      &:hover{
        background-color: #e30715;
        color: white;
        transition: 0.5s all ease;
      }
      &.programation{
        &:hover{
          background-color: #635c5f;
          color: white;
        }
      }
    }
  }
  .show-item-container-mobile{
    border-bottom: 2px solid #b2aeb0;
    text-align: left;
    padding: 10px 0px;
    cursor: pointer;  
    align-items: center;
    .inner {
      margin-bottom: 10px;
    }
    .name-container {
      &:after {
        content: "";
        display: flex;
        border-bottom: 1px solid #d9d7d8;
        margin: 10px 0;
      }
    }
    .type-line {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .activity-type{
      display: flex;
      width: 50%;
      font-size: 10px;
      padding: 10px 0;
      font-weight: 600;
    }
    .access-info{
        display: flex;
        align-items: center;
      span {
        font-size: 12px;
        padding: 0 10px;
      }
    }
    .bell-icon, .access-icon {
      display: flex;
      width: 50% !important;
      justify-content: flex-end;
      &.absolute {
        width: unset !important;
        position: absolute;
        right: 15px;
      }
    }
    .description-container {
      .access-info {
        margin-bottom: 5px;
        span {
          font-size: 12px;
          padding: 0 10px;
        }
      }
    }
    &.programation{
      padding: 0px 12px 0px 0px;
    }
    &.current-time{
      border-top: 2px solid #e30715;
    }
    .show-name{
      display: flex;
      align-items: center;
      &.no-margin{
        margin-left: unset;
      }
    }
    // .notification-button{
    //   margin-left: auto;
    // }
  }
  .name-container-fira{
      &:after {
        content: "";
        display: flex;
        border-bottom: 1px solid #d9d7d8;
        margin: 10px 0;
      }
      .type-line {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .activity-type{
        display: flex;
        width: 50%;
        font-size: 10px;
        padding: 10px 0;
        font-weight: 600;
      }
      .access-info{
          display: flex;
          align-items: center;
        span {
          font-size: 12px;
          padding: 0 10px;
        }
      }
      .bell-icon, .access-icon {
        display: flex;
        width: 50% !important;
        justify-content: flex-end;
        &.absolute {
          width: unset !important;
          position: absolute;
          right: 15px;
        }
      }
      .description-container {
        .access-info {
          margin-bottom: 5px;
          span {
            font-size: 12px;
            padding: 0 10px;
          }
        }
      }
      &.programation{
        padding: 0px 12px 0px 0px;
      }
      &.current-time{
        border-top: 2px solid #e30715;
      }
      .show-name{
        display: flex;
        align-items: center;
        &.no-margin{
          margin-left: unset;
        }
      }.type-line {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .activity-type{
      display: flex;
      width: 50%;
      font-size: 10px;
      padding: 10px 0;
      font-weight: 600;
    }
    .access-info{
        display: flex;
        align-items: center;
      span {
        font-size: 12px;
        padding: 0 10px;
      }
    }
    .bell-icon, .access-icon {
      display: flex;
      width: 50% !important;
      justify-content: flex-end;
      &.absolute {
        width: unset !important;
        position: absolute;
        right: 15px;
      }
    }
    .description-container {
      .access-info {
        margin-bottom: 5px;
        span {
          font-size: 12px;
          padding: 0 10px;
        }
      }
    }
    &.programation{
      padding: 0px 12px 0px 0px;
    }
    &.current-time{
      border-top: 2px solid #e30715;
    }
    .show-name{
      display: flex;
      align-items: center;
      &.no-margin{
        margin-left: unset;
      }
    }
    }
}





.show-ellipsis{
  overflow: hidden;
  text-transform: capitalize;
  text-overflow: ellipsis;// This is where the magic happens;
  font-size: 14px;
  .show-title {
    font-weight: 600;
  }
  .show-subtitle {
    font-weight: 300;
  }
  .off-section{
    font-size: 10px;
    color: #E0C050;
    font-weight: 600;
    
  }
}