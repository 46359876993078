@import "./_variables.scss";

.container-agenda {
  width: 100%;
  .laMevaAgendaTitle{
    max-width: 550px;
    div{
      max-width: 100%;
    }
  }
  .agenda-title {
    display: flex;
    align-items: center;
    margin-bottom: -15px;
    margin-top: 55px;
    gap:9px;
    h2{
      margin: 0;
    }
  }

}