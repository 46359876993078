@import "../_variables.scss";

.image-profile{
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: $accent-color;

  font-family: "GeneralSans-Regular";
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  color: white;

  background-size: contain;
  background-position: center center;
  &.big{
    width: 56px;
    height: 56px;
  }
}