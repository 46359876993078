@import "./_variables.scss";

.login {
  display: flex;
  height: 100vh;
}

.input-container {
  flex: 1;
  background-color: $primary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 1257px) {
    width: 100%;
  }
}

.input-title {
  color: white;
  max-width: 528px;
  min-height: 87px;
  text-align: center;
  padding: 0 10px;
}

.inputs {
  margin-top: 20px;
  background-color: white;
  gap: 34px;
  border-radius: 0px 0px 24px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 3rem;
  min-width: 528px;

  @media screen and (max-width: $mobile-screen) {
    min-width: 80%;
  }
}

.login-image {
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../img/portada-login.jpg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  @media screen and (max-width: 1257px) {
    display: none;
  }
}

.links {
  color: $accent-color;
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;

  @media screen and (max-width: $mobile-screen) {
    flex-direction: column;
    align-items: center;
    gap: 12px
  }
}

.error {
  color: $error-color
}