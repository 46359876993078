@import "../_variables.scss";
.filterContainer{
  margin-top: 32px;

  .filter-acordion-header{
    position: relative;    
    z-index: 2;
    display: flex;
    align-items:  stretch;
    gap:8;
    .filter-info{
      flex:1;
      display: flex;
      align-items:  center;
      background-color: white;
      font-family: 'GeneralSans-Regular';
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.015em;
      color:$dark-grey;
      padding: 0 16px;      
    }
    .filter-acordion-header-select-view-container{
      display: flex;
      gap: 8px;
      background-color: white;
      align-items: center;
      .filter-acordion-header-select-view-button{
        cursor: pointer;
        padding: 0;
        height: auto;
        border: 0;
        background-color: white;
        &.active{
          path{
            fill: $light-accent;
          }
        }
      }
    }
  }

  .filter-acordion-body{  
    transform: translateY(-20px);
    max-height: 0;
    background-color: $light-grey-3;
    transition: max-height .5s ease-in-out;
    overflow: hidden;

    &.open{
      max-height: 1200px;
      .filter-acordion-body-container{
        display: block;
      }
    }

    .filter-acordion-body-container{
      padding: 36px 16px 8px 16px;
      display: none;
      .title{
        font-family: 'GeneralSans-Regular';
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.015em;
        text-align: left;
        margin-bottom: 8px;
      } 
    }

  }

  @media screen and (min-width: $mobile-screen){
    margin-top: 24px;
  }
}