.Notification{
  display: flex;
  height: inherit;
  .notification-container{
    width: 30%;
    padding: 20px 10px;
  }
  .notification-container-mobile{
    width: 100%;
  }
  .notification-element{
    width: 70%;
    padding: 20px 10px;
    .xat-header{
      height: 40px;
      display: flex;
      align-items: center;
      .xat-avatar{
        padding: 10px;
      }
      .xat-title{
        font-weight: 600;
      }
    }
    .notification-containers {
      height: 500px;
      display: flex;
      position: relative;

      .title-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 10%;
        font-size: 12px;
        margin-bottom: 15px;
        border-bottom: 1px solid black;

        .title-text {
          display: flex;
          padding-bottom: 5px;
          width: 100%;
        }
        .name {
          font-weight: 600;
        }
      }
      
      .notification-messages{
        width: 100%;
        text-align: left;

        @media only screen and (max-width: 768px) {
          height: 300px;
          overflow-y: scroll;
        }
        .timestamp{
          border-bottom: 1px solid #5B91F0;
          padding: 20px 0px;
          color: #5B91F0;
          font-weight: 600;
        }
        .title{
          font-weight: 600;
          margin: 20px 0px;
        }

      }

      .form-container {
        height: 10%;
      }

      .form-msg{
        padding: 20px;
        border-radius: 10px;
        background-color: #EAE8E0;
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
        width: 100%;
        position: absolute;
        margin-top: 20px;
        justify-content: space-between;
        .styled-input{
          width: 90%;
        }
      }

    }
    
  }
}

.notification-container-mobile{
  .notification-messages{
    width: 100%;
    text-align: left;
    font-size: 18px;
    .timestamp{
      border-bottom: 1px solid #5B91F0;
      padding: 20px 0px;
      color: #5B91F0;
      font-weight: 600;
    }
    .title{
      font-weight: 600;
      margin: 20px 0px;
    }

  }
  .title-container-mobile{
    display: flex;
  }
  .notifications-list{
    @media only screen and (max-width: 768px) {
      height: 100%;
      overflow: unset;
      padding:unset;
    }
  }
  .notification-container{
    .notification-messages{
      height: 300px;
      flex-direction: column-reverse;
      display: flex;
      overflow-y: scroll;
      @media only screen and (max-width: 768px) {
        min-height: 300px;
        height: unset;
        overflow-y: unset;
      }
      .date-small{
        font-size: 8px;
        opacity: 0.8;
      }
      &.empty{
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          font-size: 50px;
          fill: #bbbbbc;;
        }
      }
    }
  }
}

.form-container-mobile{
  .form-msg{
    display: flex;
    margin-top: 20px;
  }
  .styled-input{
    width: 80%;
  }
  .rce-button{
    margin-left: auto;
  }
}



.notification-list{
  height: 500px;
    overflow-y: auto;
    
    padding: 0px 10px;
  .title-container {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 10px 0 20px 0;
    font-size: 18px;
    font-weight: 600;
  }

  .notification-list-element{
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 10px;
    height: 120px;
    display: flex;
    padding: 10px 20px;
    cursor: pointer;
    margin-left: auto;
    align-items: center;
    margin-bottom: 5px;
    background-color: #F5F4F0;
    &.selected{
      background-color: #3D3338;
      color: white;
    }
    @media only screen and (max-width: 768px) {
      margin: 5px 0px;
      background-color: unset;
      border-bottom: 1px solid rgba(128,128,128,0.2);
      border-radius: 0px;
      height: unset;
      font-size: 16px;
      padding: 10px 0px;
    }
    .notification-list-info{
      display: flex;
      flex-direction: column;
      font-size: 18px;
      width: 100%;
      div {
        text-align: left;
      }
      .blue{
        color: #5B91F0;
      }
      .bold{
        font-weight: 600;
      }
      .limited{
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .pending-container{
      background-color: #FA8E28;
      color: white;
      font-weight: 600;
      border-radius: 100%;
      width: 30px;
      height: 24px;
      margin-left: auto;
      padding: 2px;
    }
  }
  .notification-list-element:hover{
    background-color: #5B91F0;
    color: white;
    .blue{
      color: white;
    }
    &.selected{
      background-color: #3D3338;
      color: white;
      .blue{
        color: #5B91F0;
      }
    }
  }
}

.bubble-container{
  width: 100%;
  padding: 3px 10px;
.bubble-text{
  background-color: #F5F4F0;
  font-size: 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  text-align: left;
  padding: 7px 17px;
  width: 55%;
  @media only screen and (max-width: 768px) {
    width: 80%;
  }
  &.left{
    border-top-left-radius: 0px;
    float: left;
  }
  &.right{
    border-top-right-radius: 0px;
    float: right;
  }
  // animation-fill-mode: forwards; 
  // animation-duration: 1s;
  // animation-name: createBox;
  // position: relative;

  // @keyframes createBox{
  //     0%{
  //       opacity: 1;
  //         width: 10%;

  //     }
  //     100%{
  //       opacity: 1;
  //         width: 60%;
  //     }
  //   }
  }
}

.svg-icon-class{
  width: 50px;
  height: 50px;
  background-color: #8B8588;
  border-radius: 100%;
  padding: 5px;
  margin-right: 10px;
  fill: #D9D7D8
  
}