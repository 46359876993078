@import "./_variables.scss";
.chipTypeShow{
  &.professional{
    height: auto;
    border-color: $light-accent;    
  }
  &.off{
    height: auto;
    border-color: $dark-grey;    
  }
  &.ofical{
    height: auto;
    & .MuiChip-label {
      color: white;    
    }  
    background-color: $light-accent;  
    border-color: $light-accent;    
  }
  & .MuiChip-label {
      font-family: 'GeneralSans-Regular';;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.015em;
      text-align: left;
      padding: 0 8px;
      color: $dark-grey;   
    }
}

.table-row-mobile {  
  .chipTypeShow {    
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
