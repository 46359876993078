@import "./_variables.scss";

.firaPasseigPage{
    padding: 30px;

    ul{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .firaLink{
        margin: 40px 0px;
        p{
            margin: 0;
        }
        a{
            color: $info-color;
        }
    }

    h4{
        color: $accent-color;
    }
}