@import "./_variables.scss";

.account {
  box-sizing: border-box;

  h2 {
    margin: -24px 0px;
  }

  .account-class {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .accountSectionTitle:first-letter {
      text-transform: uppercase;
    }
  }

  .entityDetailItem_list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  .entityDetailItem {
    .entityDetailItem_last {
      min-width: 300px;
      flex: 48%;
    }

    .entityDetailItem_element {
      min-width: 300px;
      flex: 48%;
      height: fit-content;
      border-radius: 0px 0px 25px 0px;
      border: solid 1px $light-grey;

      .entityDetailItem__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 40px;
        padding: 0px 15px;
        background-color: #F0EBE899;
        margin-bottom: 15px;

        p {
          margin: 0px;
          font-family: 'Aventa Variable';
          font-size: 16px;
          font-weight: 800;
          line-height: 24px;
          text-align: left;
        }
      }

      .entityDetailItem__box {
        display: flex;
        flex: 1;
        margin: 20px 15px;
        gap: 10px;
        flex-wrap: wrap;

        .entityDetailItem__box__item {
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        .entityDetailItem__box__item__title {
          display: flex;
          align-items: center;
          gap: 10px;

          .badges_basic {
            display: none;
          }
        }

        p {
          margin: 0;
        }

        .project-resume-videos {
          p {
            font-size: 14px;
            color: $dark-grey;
          }

        }
      }

      .entityDetailItem__box_Video {
        text-decoration: underline;
        font-size: 14px;
        color: $dark-grey;
      }
    }

    .disabled {
      opacity: 0.3;
    }
  }

  .entityDetailItem__box_acreditation,
  .entityDetailItem__box_stand,
  .entityDetailItem__box_shop {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }

  .entityDetailItem__box_acreditation {
    align-items: center;
  }

  .badges_Chips {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    gap: 16px;

    .badges_basic {
      display: inherit;
    }
  }

  .entityImg {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 8px;
    background: $accent-color;
    margin-right: 10px;

    font-family: "GeneralSans-Regular";
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    color: white;
  }

  a {
    color: $accent-color;
    text-decoration: none;
  }

  // DETAILS
  .separator-entity {
    border-bottom: 1px solid;
    opacity: 0.2;
    transform: scaleX(1.025);
  }

  .separator {
    border-bottom: 1px solid;
    opacity: 0.2;
    margin: 0px 15px;
  }

  @media screen and (max-width: $mobile-screen) {
    .account-class {
      gap: 16px;
    }

    .separator-entity {
      margin-top: 16px;
      transform: scaleX(1);
    }

    h2 {
      margin: 0;
    }

    .basic-chips {
      height: 40px;
    }

    .badges_Chips {
      margin-left: 50px;

      .badges_basic {
        display: none;
      }

    }

    .entityDetailItem {
      .entityDetailItem_element {
        width: 100%;

        .entityDetailItem__title {
          height: 56px;
        }

        .entityDetailItem__box {
          .entityDetailItem__box__item__title {
            .badges_basic {
              display: inherit;
            }
          }

        }

        .entityDetailItem__box_acreditation {
          .badges_basic {
            display: none;
          }

        }
      }
    }
  }
}