@import "../_variables.scss";

.base-button {
  min-height: 56px;
  max-width: 400px;
  padding: 16px 32px;
  gap: 8px;
  border-radius: 0px 0px 24px 0px;
  display: flex;
  justify-content: center;
  font-family: 'GeneralSans-Variable';
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  align-items: center;
  text-align: left;
  border-color: transparent;
  cursor: pointer;
  border-style: solid;

  svg {
    height: 24px;
    width: 24px;
  }

  &.small {
    height: 40px;
    min-height: 40px;
    gap: 12px;
    padding: 0 16px;
    font-size: 16px;
    svg {
      height: 16px;
      width: 16px;
    }
  }

  &.primary {
    background-color: $primary-color;
    color: white;

    &:hover {
      background-color: $accent-color;
    }

    &.outlined {
      color: $primary-color;
      border-color: $primary-color;
      background-color: white;

      &:hover {
        color: $accent-color;
        border-color: $accent-color;
        background-color: white;
      }
    }
  }

  &.secondary {
    background-color: $accent-color;
    color: white;

    &.outlined {
      color: $accent-color;
      border-color: $accent-color;
      background-color: white;
    }
  }

  &.light-grey {
    background-color: $light-grey-2;
    color: black;
  }

  &.primaryOutlinedIcon {
    background-color: white;
    color: $primary-color;

    &.outlined {
      color: $primary-color;
      border-color: $primary-color;
      background-color: white;

      &:hover {
        color: $accent-color;
        border-color: $accent-color;
        box-shadow: 0px 2px 1px 0px rgba(#A6A6A6, 0.5);

        svg path {
          fill: $accent-color;
        }
      }
    }
  }

  .icon-end {
    height: 24px;
    margin-right: -8px;
  }

  &:disabled{
    cursor: not-allowed;
    &.primary {
      background-color: $dark-grey;
      color: $light-grey-2;
  
      &:hover {
        background-color: $dark-grey;
      }
  
      &.outlined {
        color: $dark-grey;
        border-color: $dark-grey;
        background-color: $light-grey-2;
  
        &:hover {
          color: $dark-grey;
          border-color: $dark-grey;
          background-color: $light-grey-2;
        }
      }
    }
  }

  @media screen and (max-width: $mobile-screen){
    padding: 16px;
    gap: 16px;
  }
}