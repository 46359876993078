@import "../_variables.scss";

.dialog {
    .MuiPaper-root {
      width: auto;
      height: auto;
      padding: 40px 100px 40px 50px;
      align-items: normal;
      color: $info-color;
    }
    .MuiDialog-paper {
        display: flex !important;
        justify-content: center;
        align-items: center;
        border-radius: 0px 0px 26px 0px !important;
    }
    
    .cross-icon {
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
        svg {
            width: 22px;
            height: 22px;
        }
    }
        
    .MuiDialogTitle-root {
        padding: 0px !important;
        h4{
            margin:0;
        }
    }
    
    .MuiDialogContent-root {
        padding: 0px !important;
    }
    
    .MuiDialogActions-root {
        padding: 0px !important;
        justify-content: start !important;
        margin-top: 32px;
        gap:16px;
    }
    
    .dialog-content {
        display: flex;
        flex-direction: column;
    }
  
    p {
      color: $info-color;
    }
    
    svg {
      width: 56px;
      height: 56px;
      transform: translateX(-10px);
    }
    @media screen and (max-width: $mobile-screen){
        .MuiPaper-root {
          padding: 40px 26px 32px 26px;
        }
      }
}