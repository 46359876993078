@import "./_variables.scss";

.show-grid {
  display: grid;
  margin-top: 32px;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: $large-screen) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: $medium-screen) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px;
  }

  @media (max-width: $mobile-screen) {
    margin-top: 24px;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 45px;
  }


  .show-grid-item {
    align-self: center;
    justify-self: center;
    background-color: white;
    display: grid;
    //grid-auto-columns: minmax(285px, 350px);
    width: -webkit-fill-available;
    grid-template-rows: 214px 250px 40px;
    grid-template-areas:
      "img"
      "main"
      "footer";
      border: 0.5px solid #5C5958;
    
  
    .show-head {
      grid-area: img;
      position: relative;
      background-position: center center;
      background-size: cover;  
      background-color: black;
    }
  
    /*cancel·lat*/
    .show-mask {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #d60d1a80;
      height: 100%;
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;
  
      div {
        color: $white;
        font-size: 1.75rem;
        line-height: 1.875rem;
        font-weight: $extra-bold-font-weight;
        letter-spacing: 0.03em;
        text-transform: uppercase;
      }
    }
  
    /*canvi d'hora*/
    .show-mask-time-change {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      justify-content: center;
      background-color: #2b2a29cc;
      height: 100%;
      width: 100%;
      position: relative;
      top: 0;
      left: 0;
      z-index: 1;
  
      &__icon {
        display: inline-flex;
        flex-shrink: 0;
        padding: 0 10px 0 0;
  
        svg {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 32px;
          width: 32px;
        }
      }
  
      &__label {
        //flex: 1 1 auto;
        color: $yellow-warning;
        font-size: 1.75rem;
        line-height: 1.875rem;
        font-weight: $extra-bold-font-weight;
        text-transform: uppercase;
        letter-spacing: 0.03em;
      }
    }
  
    .show-main {
      overflow: auto;
      grid-area: main;
      margin: 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;     
    }

    .show-main-items {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;  
    }
  
    .show-ambits {
      color: $dark-grey;
      font-size: 0.875rem;
      font-weight: $normal-font-weight;
      letter-spacing: 0.02em;
      line-height: 1.375rem;
      padding-top: 5px;
      letter-spacing: -0.01em;
    }
  
    .show-artists {
      color: $primary-color;
      font-size: 1.188rem;
      font-weight: $bold-font-weight;
      text-transform: uppercase;
      letter-spacing: -0.04em;
      line-height: 1.5rem;
      //padding-top: 8px;
      margin-top: 8px;
      @include multiline-ellpisis(2, white, 1.5rem);
  
    }
  
    .show-title {
      font-family: "GeneralSans-Regular";
      color: $accent-color;
      font-size: 1.188rem;
      font-weight: $bold-font-weight;
      line-height: 1.375rem;
      padding-top: 8px;
      letter-spacing: -0.04em;
      text-transform: uppercase;
    }
  
    .show-calendar {
      display: flex;
      flex-grow: 1;
      justify-content: flex-start;
      margin: 0px;
      padding: 8px 0px;
      position: relative;
      text-align: left;
      font-size: 0.875rem;
      line-height: 1.375rem;
  
      &__icon {
        display: inline-flex;
        flex-shrink: 0;
        width: 25px;
        padding: 4px 0;
  
        svg {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 16px;
          width: 14.4px;
        }
      }
  
      &__text {
        flex: 1 1 auto;
  
        b {
          font-weight: 500;
          text-transform: capitalize;
        }
  
        &__canceled {
          text-decoration:line-through #E90C1B;
          
          &__title {
            text-decoration-line: none !important;
            color: $accent-color;
            font-weight: $bold-font-weight !important;
            letter-spacing: -0.015em;
          }
        }
      }
    }
  
    .show-item-footer {
      grid-area: footer;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      font-family: "GeneralSans-Regular";
      color: white;
      font-size: 0.75rem;
      font-weight: $bold-font-weight;
  
      a {
        color: $white;
        text-decoration: none;
      }
    }
  
    .show-performance-options {
      background-color: $dark-grey;
      flex: 60%;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 10px;
      line-height: 1rem;
  
      &__icon {
        display: inline-flex;
        flex-shrink: 0;
  
        svg {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 18.75px;
          width: 15px;
        }
      }
  
      &__label {
        flex: 1 1 auto;
      }
  
    }
  
    .show-info {
      cursor: pointer;
      background-color: $accent-color;
      flex: 40%;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 10px;
  
      &__icon {
        display: inline-flex;
        flex-shrink: 0;
  
        svg {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 17px;
          width: 17px;
          path{
            fill: white;
          }
        }
      }
  
      &__label {
        margin-left: 8px;
      }
  
    }
  
  }

}