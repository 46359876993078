@import "./styles/LoadingStyles.scss";
@import "./styles/LiveStyles.scss";
@import "./styles/GenericStyles.scss";
@import "./styles/HomeStyles.scss";
@import "./styles/PropostesStyles.scss";
// @import "./styles/FormStyles.scss";
@import "./styles/ProfessionalsStyles.scss";
@import "./styles/ScrollbarStyles.scss";
@import "./styles/ShowsStyles.scss";
@import "./styles/SidebarStyles.scss";
@import "./styles/SliderStyles.scss";
@import "./styles/XatStyles.scss";
@import "./styles/AccountStyles.scss";
/* @import "./styles/AccountGuillotinaStyles.scss"; */
@import "./styles/SelectorStyles.scss";
@import "./styles/Footer.scss";
/* @import "./styles/EntradesStyles.scss"; */
@import "./styles/NotificationStyles.scss";
@import "./styles/Switch.scss";
@import "./styles/spacing-helpers.scss";
@import "./styles/PaymentStyles.scss";
@import "./NewVersion/styles/_variables.scss";

html, 
body {
    height: 100%;
    width: 100%;
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  h1 {
    font-family: 'Aventa Variable';
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: left;
  }
  
  h2 {
    font-family: 'Aventa Variable';
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0.01em;
  }

  h3 {
    font-family: 'Aventa Variable';
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0.01em;
    text-align: left;
  }

  h4 {
    font-family: 'Aventa Variable';
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: left;
  }

  h5 {
    font-family: 'Aventa Variable';
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.01em;
    text-align: left;
  }

  p {
    //styleName: Body 1;
    font-family: 'GeneralSans-Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-align: left;
  }

  .body-bold {
    //styleName: Body 1 Bold;
    font-family: 'GeneralSans-Semibold';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.015em;
    text-align: left;
  }
  
  .body-2-bold  {
    //styleName: Body 2 Bold;
    font-family: 'GeneralSans-Regular';
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.015em;
    text-align: left;    
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.container-fluid.flex-container{
  height: 100%;
  .row{
    display: flex;
    justify-content: center;
    height: inherit;
  }
  span{
    float:left;
  }
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #E30715;
  height: 84px;
  &.top{
    transition: all 0.5s;
    height: 84px;
  }
  &.notTop{
    transition: all 0.5s;
    height: 42px;
  }
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  z-index: 3;
  a{
    color: white;
    text-transform: none;
    font-size: 24px;
    font-weight: 600;
  }
  a:hover{
    color: white !important;
    text-decoration: none !important  ;
  }
  
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  transition: transform .2s;
  &.scrolled{
    transform: translateY(-100%);
  }
  @media screen and (max-width: 768px) {
      display: block;
      height: auto;
  }
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-title{
  text-align: center;
  font: Black 24px/32px Roboto;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 30px;
}

.footer-separator{
  border-left: 1px solid white;
}

.profile-separator{
  border-top: 1px solid black;
  margin: 25px 0px;
}

.mobile-show-separator-horizontal{
  border-top: 1px solid grey;
  margin: 5px 0px;
  opacity: 0.5;
}

.overlay-class{
  background-color: unset !important;
  width: inherit;
  left: unset !important;
}

.page-content{
  // padding: 0px 10vw;
  &.autenticated {
    display: flex;
    background-color: white;
  }
  padding: 0px 0px;
  @media screen and (max-width: $mobile-screen) {
    // padding: 0px 5vw;
    //padding: 24px;
  }
  &.scroll{
    .home-link{
      background-position: revert;
    }
    @media screen and (max-width: 768px) {
      .xat-container{
        .go-back-container{
          &.scrolled{
            transform: translateY(-100%);
            top: 0px;
          }
        }
      }
    }
    // .shows-filters{
    //   transform: translateY(-100%);
    //   top: 0px;
    // }
    // .shows-filters-mobile{
    //   transform: translateY(-100%);
    //   top: 0px;
    // }
  }
}

.content{
  min-height: 778px;
  flex: 1;
  @media screen and (max-width: 768px) {
    min-height: unset;
  }
}

.svg-elements{
  display: flex;
    align-items: center;
    color: white;
    justify-content: left;
  .svg-container{
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin: 0px 3px;
    &.facebook:hover{
      svg{
        fill: #3d5a99;
        transition: 0.5s all;
      }
    }
    &.linkedin:hover{
      svg{
      fill: #0277b5;
      transition: 0.5s all;
      }
    }
    &.youtube:hover{
      svg{
      fill: #ff0400;
      transition: 0.5s all;
      }
    }
    &.instagram:hover{
      svg{
      fill: #e1316c;
      transition: 0.5s all;
      }
    }
    &.twitter:hover{
      svg{
      fill: #1fa1f2;
      transition: 0.5s all;
      }
    }
  }
}


@keyframes in {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes out {
  from {opacity: 1;}
  to {opacity: 0;}
}
.ease {
  opacity: 0;
  animation: in 0.3s ease 0.3s forwards 1;
}

.user-preferences{
  text-align: left;
  .preferences-title{
    font-size: 20px;
    font-weight: 600;
    margin-top: 25px;
  }

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #e30a15;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
}

.center-elements{
  display: flex;
  align-items: center;
  width: 133px;
  justify-content: space-between; 
  @media screen and (max-width: 768px) {
    width: unset;
  }
}

.align-elements{
  display: flex;
  align-items: center;
}

.center-elements-all{
  display: flex !important;
  align-items: center;
}

.schedule-container{
  max-width: 320px;
  @media screen and (max-width: 768px) {
    overflow-y: unset;
    max-height: unset;    
    max-width: unset;
  }
  .info-small{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    .specific-date{
      a {
        color: white;
        text-transform: uppercase;
        text-decoration: none;
      }
      width: 100%;
      @media screen and (max-width: 768px) {
        &:after {
          content: "";
          display: flex;
          border-bottom: 1px solid #3D3338;
          opacity: 20%;
          padding-top: 10px;
        }
      }
    }
  }
  span{
    display: flex !important;
    align-items: center;
    margin-bottom: 2px;
    svg{
      margin-right: 5px;
    }
  }
}


.Notifications{
  .notification-item-container{
    padding: 0px 12px;
    border-bottom: 1px solid;
    height: 68px;
    text-align: left;
    cursor: pointer;  
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name-container{
      display: flex;
      // height: 55px;
    }
    .access-info{
      padding: 0px 12px 0px 12px;
      border-left: 1px solid #cac8c9;
      display: flex;
      align-items: center;
      height: 55px;
      width: 10vw;
      overflow: hidden;
      &.right{
        width: unset;
      }
    }
    .activity-type{
      height: inherit;
      display: flex;
      align-items: center;
      font-size: 12px;
      border-right: 1px solid #cac8c9;
      padding: 0px 10px 0px 70px; 
    }
    &.programation{
      padding: 0px 12px 0px 0px;
    }
    .show-name{
      width: 30vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      padding-left: 15px;
      &.no-margin{
        margin-left: unset;
      }
    }
    // .notification-button{
    //   margin-left: auto;
    // }
  }
}

.professional-entity{
  width: 160px;
  margin-left: 30px;
  text-align: right;
}

.styled-input{
  input{
    display: block !important;
    border: 1px solid transparent !important;
    border-radius: 10px !important;
    width: 100%;
    height: 100%;
  }
  input:focus {
    outline-width: 0;
  }
}


.stream-container{
  display: flex;
  // grid-template-columns: 1fr 1fr 1fr;
  // grid-gap: 20px 20px;
  height: 100vh;
  height: 100% !important;
  background-color: #b2aeb0;
  @media screen and (max-width: 768px) {
    display: grid;
  }
}

.primary-red{
  color: #dc0713;
}


.full-page-loader {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.full-page-loader > a > img {
  animation: 1.8s infinite heartbeat;
}

@keyframes heartbeat {
  0% { transform: scale(1); }
  25% { transform: scale(1.05); }
  50% { transform: scale(1); }
  75% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.calendar-svg{
  width: 25px;
  cursor: pointer;
  margin-bottom: 3px;
}

input[type=radio] {
  cursor: pointer;
}

input[disabled] {
  background-color: hsl(0,0%,95%);
}

.error-message{
  color: #E30715
}


.alert-info-tarifes {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.alert-danger{
  color: $accent-color;
}

.hide-sm {
  display: block;
  @media screen and (max-width: $mobile-screen){
    display: none;
  }
}
.display-sm {
  display: block;
  @media screen and (min-width: $mobile-screen){
    display: none;
  }
}

.hide-md {
  display: block;
  @media screen and (max-width: $medium-screen){
    display: none;
  }
}
.display-md {
  display: block;
  @media screen and (min-width: $mobile-screen){
    display: none;
  }
}

.page-wrapper {
  margin: 42px 24px;
  width: 100%;
}