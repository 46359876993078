@import "../_variables.scss";

.badges_basic{
    display: flex;
    align-items: center;
    gap: 10px;
    border: solid 1px white;

    /* height: 24px; */
    padding: 0px 8px;
    border-radius: 32px;

    font-size: 14px;

    svg{
        width: 16px;
        height: 16px;
    }
}

.actua{
    border-color: $info-color;
    color: $info-color;
}
.esborrany{
    border-color: $dark-grey;
    color: $dark-grey;
}
.presentada{
    border-color: $correct-color;
    color: $dark-grey;
    svg circle{
        fill: $correct-color;
        stroke: $correct-color;
    }
}
.pagamentPaid{
    border-color: $correct-color;
    color: $primary-color;
    svg circle{
        fill: $correct-color;
    }
}
.pagamentPendent{
    border-color: $yellow-warning;
    color: $primary-color;
    svg path{
        fill: $yellow-warning;
    }
}
.accountAlert{
    font-size: 14px;
    font-weight: 600;

    border-color: $yellow-warning;
    color: $primary-color;
    svg path{
        fill: $yellow-warning;
    }
}
.pro{
    border-color: $accent-color;
    color: $primary-color;
    text-transform: capitalize;
    font-weight: 600;
}
.oficial{
    border-color: $accent-color;
    background-color: $light-accent;
    color: white;
    text-transform: capitalize;
    font-weight: 600;
}
.off{
    border-color: $dark-grey;
    color: $dark-grey;
    font-weight: 600;
}