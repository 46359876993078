@import "../_variables.scss";
.filter-search{
  display: flex;
  border: 0.7px solid $dark-grey;
  gap:17px;
  align-items: center;
  padding: 15px 18px;
  width: 100%;
  max-width: 450px;
  input{
    width: 100%;
    font-family: 'GeneralSans-Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color:$primary-color;
    border: none;
    &:focus-visible{
      outline: 0;
    }
  }
}