body {
  margin: 0;
  font-family: 'GeneralSans-Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.015em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h3 {
  font-family: Aventa Variable;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-left: 0px;
}

h4 {
  font-family: Aventa;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.01em;
  text-align: left;
}

h5 {
  font-family: Aventa Variable;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: left;
}

h6 {
  font-family: 'GeneralSans-Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: left;
}

p {
  font-family: 'GeneralSans-Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.015em;
  text-align: left;
}
.body-2 {
  font-family: 'GeneralSans-Regular';
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.015em;
  text-align: left;
}
.body-2-bold {
  font-family: 'GeneralSans-Regular';
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.015em;
  text-align: left;
}

a {
  color: inherit;
}

.reset-anchor {
  text-decoration: none;
  color: inherit;
}
