@import "../_variables.scss";

.button-language-group {

    display: flex;
    align-items: center;
    height: 100%;

    .MuiButtonGroup-grouped  {
        color: white;
        font-family: 'GeneralSans-Regular';
        font-size: 13px;
        font-weight: 400;
        line-height: 17.55px;
        letter-spacing: 0.01em;
        text-align: center;

    }

    .MuiButtonGroup-groupedTextHorizontal:not(:last-child) {
        border-right: 1px solid white;
    }

    .selected {
        font-family: 'GeneralSans-Semibold';
        font-weight: 600;
    }
}
