.Professionals {
  
  .grid-container {
    display: grid;
    column-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
  }

  .item-container-grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    justify-content: space-between;
  }

  .professional-description {
    // width: 160px;
    text-align: right;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    width: 200px;
    padding-right: 12px;
  }

  .professional-title {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    min-width: 100px;
    padding-left: 12px;
    flex-wrap: wrap;
    max-width: 266px;
  }

  .name-info-2 {
    display: grid;
    grid-template-columns: 1fr 10px 1fr;
    align-content: center;
  }

  .professional-separator-2 {
    content: "";
    border-left: 1px solid #b4b1b2;
    margin: 0px 12px;
    height: 60px;
    max-width: 3px;
    opacity: 0.5  ;
  }

  @media only screen and (max-width: 768px) {
    .grid-container {
      display: grid;
      grid-template-columns: 1fr;
      justify-content: unset;
    }
  
    .item-container-grid {
      display: grid;
      grid-template-columns: 3fr 1fr;
      justify-content: space-between;
    }
  
    .professional-description {
      display: flex;
      align-items: center;
      justify-content: unset;
      text-align: left;
      margin-left: unset;
      flex-shrink: unset;
      min-width: unset;
      padding-right: unset;
    }
  
    .professional-title {
      display: flex;
      align-items: center;
      justify-content: unset;
      flex-shrink: unset;
      min-width: unset;
      padding-left: unset;
    }
  
    .name-info-2 {
      display: flex;
      flex-direction: column-reverse;
      align-content: space-between;
      align-self: center;
    }
  
    .professional-separator-2 {
      display: none;
    }
  }

  .inner-prof {
    // width: 49%;
    height: 82px;
    margin-bottom: 5px;
    display: flex;
    border-bottom: 1px solid beige;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: unset;
    }
  }

  .prof-item-container-grid{
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 72px;
    &:hover {
      background-color: #3d3338;
      color: white;
    }
  }

  .prof-item-container-grid-mobile {
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .show-name {
      display: flex;
      width: 70%;
      text-align: left;
      padding: 7px 0;
      .show-ellipsis {
        .show-subtitle {
          font-size: 12px;
        }
      }
    }
    .actions-info {
      display: flex;
      justify-content: flex-end;
      width: 30%;
      padding: 10px 0;
      * {
        margin: 0 5px;
      }
    }
  }
}

.Professional{
  min-height: 370px;
  display: flex;

  .image-professional {
    width: 38%;
    background-color: #b2aeb0;
    .image {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      height: 100%;
    }
  }

  .info-professional {
    padding: 30px;
    width: 62%;
    text-align: left;

    .name{
      font-size: 30px;
      font-weight: 600;
      cursor: pointer;
      display: flex;
      text-transform: capitalize;
      .contact-icons{
        display: flex;
        margin-left: auto;
      }
      &.small{
        font-size: 20px;
        cursor: unset;
      }
    }
    .parent{
      font-size: 24px;
    }
  }

  .professional-separator-horizontal{
    margin: 18px 0px;
    border-top: 1px solid #b2aeb0;
  }
  .info-professional-child{
    text-transform: capitalize;
    display: inherit;

    .info-professional-adress{
      text-transform: capitalize;
      width: 50%;
      overflow-y: auto;
    }
    .info-professional-members > *:first-child{
      font-weight: 600;
    }
    .info-professional-members{
      width: 50%;
      overflow-y: auto;
      div{
        padding: 5px 0px;
      }
    }
  }
}

.Professional-mobile {
  display: flex;
  flex-direction: column;

  .image-professional {
    display: flex;
    margin: 0px -4vw !important;
    background-color: #b2aeb0;
    height: 312px;
    .image {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
    }
  }

  .info-professional {
    text-transform: capitalize;
    .description-container {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
    }
    .description-text {
      text-align: left;
      font-size: 12px;
      &.acreditat{
        font-size: 14px;
        font-weight: 600;
      }
    }
    .name-text {
      font-size: 18px;
      font-weight: 600;
    }
    .charge-text {
      font-size: 11px;
      font-weight: 600;
    }
    &:after {
      content: "";
      display: flex;
      border-bottom: 1px solid #D9D7D8;
    }
    div {
      padding: 2px 0;
    }
  }

  .actions-info {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    * {
      margin: 0 5px;
    }
  }

  .separator-professional {
    content: "";
    border-left: 1px solid #b4b1b2;
    margin: 0px 12px;
    height: 22px;
    opacity: 0.5  ;
  }
}