@import "./_variables.scss";

.home-page-container{
  max-width: 925px;
  padding: 120px 20px;

  &.page-wrapper{
    margin: 0 auto;
  }

  .home-text{
    margin: auto;
    max-width: 780px;
    h1, p {
      text-align: center;
    }
  }
  .home-button-grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:16px;
    row-gap: 24px;
    margin-top: 80px;
    .home-button{
      position: relative;
      display: flex;
      padding: 16px;
      gap:16px;
      min-height: 136px;
      width: 256px;
      background-color: $light-grey-2;
      text-decoration: none;
      color: inherit;
      border-bottom-right-radius: 48px;
      box-shadow: 1px 4px 4px 0px #00000033;
      cursor: pointer;
      .home-button-diabled-mask{
        cursor: not-allowed;
        position: absolute;
        top:0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: #F0EBE899;
        border-bottom-right-radius: 48px;
      }
      .home-button-icon{
        width: 32px;
        height: 32px;
        margin-top: 5px;
        svg{
          width: 32px;
          height: 32px;
          path{
            fill:$light-accent;
          }
        }
      }
      h4{
        margin: 0;
      }
      &.disabled{
        cursor: not-allowed;
        .home-button-icon{
          svg{
            path{
              fill:$primary-color;
            }
          }
        }

      }
    }
  }


  @media screen and (max-width: $mobile-screen){
    padding: 0;
    &.page-wrapper {
      margin: 42px 24px;
      width: 100%;
    }  
    .home-text{
      h1, p {
        text-align: left;
      }

      h1{
        margin-bottom: 25px;
      }
    }
    .home-button-grid{
      margin-top: 40px;
      justify-content: flex-start;
      .home-button{
        width: 240px;
        min-height: 93px;
      }
    }
  }
}