@import "./_variables.scss";

$sidebar-background-color: #F0EBE8;
$hovered-item-color: #D6D0CD;

.sidebar {
  @media screen and (max-width: $mobile-screen) {
    display: none;
  }

  background-color: $sidebar-background-color;
  position: relative;
  min-width: 250px;
  transition: min-width 0.6s ease;

  .sidebar-sections {
    padding-inline-start: 0px;
  }

  .section-title {
    color: $accent-color;
    display: flex;
    font-family: 'GeneralSans-Semibold';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    padding: 20px;
  }

  .section-items {
    padding-inline-start: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .item {
    display: flex;
    align-items: center;
    height: 32px;
    color: $primary-color;
    font-family: 'GeneralSans-Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    gap: 8px;
    padding: 10px 0;
    cursor: pointer;
    &.selector{
      padding: 10px;
    }
    a {
      padding: 10px;
      width: 100%;
      gap: 8px;
      display: flex;
      align-items: center;
      height: 32px;
      color: $primary-color;
      font-family: 'GeneralSans-Medium';
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      text-decoration: none;
    }

    &.selected {
      background-color: $light-grey;
    }
  }

  .item:hover {
    background-color: $light-grey;
  }

  span {
    display: inline-block;
    opacity: 1;
    visibility: visible;
    max-width: 200px;
    overflow: hidden;
    transition: opacity 0.6s, max-width 0.6s ease-in-out, visibility 0s linear 0.6s;
  }

  &.close-sidebar {
    min-width: 72px;

    .MuiInputBase-root {
      display: none;
    }

    .name {
      justify-content: center;

      .label-selector {
        gap: 0px;
      }
      .MuiSvgIcon-root {
        display: none;
      }
    }

    .item {
      justify-content: center;
      gap: 0px
    }

    span {
      opacity: 0;
      visibility: hidden;
      max-width: 0;
      transition: opacity 0.6s, max-width 0.6s ease-in-out, visibility 0s linear 0s;
    }
  }
}

.sidebar-button {
  border: none;
  background-color: transparent;
  position: absolute;
  right: -25px;
  bottom: 0;
}

.sidebar-mobile {
  background-color: $sidebar-background-color;
  position: relative;
  min-width: 250px;
  ul {
    margin:0;
  }

  .sidebar-sections {
    padding-inline-start: 0px;
  }

  .section-title {
    color: $accent-color;
    display: flex;
    font-family: 'GeneralSans-Semibold';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    padding: 20px;
  }

  .section-items {
    padding-inline-start: 0px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .item {
    display: flex;
    align-items: center;
    height: 32px;
    color: $primary-color;
    font-family: 'GeneralSans-Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    padding: 20px 0;
    gap: 8px;
    cursor: pointer;

    &.selector{
      padding: 20px;
    }

    a {
      width: 100%;
      gap: 8px;
      display: flex;
      align-items: center;
      height: 32px;
      color: $primary-color;
      font-family: 'GeneralSans-Medium';
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      padding: 20px;
      text-align: left;
      text-decoration: none;
    }

    &.selected {
      background-color: #D6D0CD;
    }
  }
}